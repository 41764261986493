import Vue from "vue";
let vue = new Vue();
import store from "../../store";

//将url,请求参数和body传入，通过Promise，将成功和失败的数据派发出去
export function doRequest(method, data, showErr = true) {
  const apiHost = store.state.host.apiHost[store.state.host.env];
  return new Promise((resolve, reject) => {
    const url = `${apiHost}/${data.url}`;
    let params = {};
    params =
      data.url === "interact/login/index"
        ? data.params
        : { ...data.params, token: store.state.auth.loginInfo.token };
    let body = {};
    if (data.body) {
      body = JSON.stringify(data.body);
    }
    vue
      .$http({
        url,
        params,
        body,
        method,
      })
      .then(
        (res) => {
          console.log("HTTP success:", res);
          if (res.status === 200 && res.body.code === 0) {
            resolve(res.body.data);
          } else if (showErr) {
            vue.$message({
              showClose: true,
              message: res.body.msg || "业务有问题哦!",
              type: "warning",
              duration: 6000,
            });
          }
        },
        (err) => {
          console.log("HTTP fail:", err);
          if (showErr) {
            vue.$message({
              showClose: true,
              message: err.body.msg || "业务出错了哦!",
              type: "error",
              duration: 6000,
            });
          }
        }
      );
  });
}
