<template>
  <div class="refund">
    <el-form
      ref="refundForm"
      :model="refundForm"
      :rules="refundFormRules"
      label-width="106px"
    >
      <el-form-item label="订单编号" prop="orderNo">
        <el-input v-model="refundForm.orderNo" placeholder="请输入订单编号">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getOrderInfo"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="渠道" prop="channel">
        <el-input
          v-model="refundForm.channel"
          placeholder="渠道"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="供应商" prop="supplier">
        <el-input
          v-model="refundForm.supplier"
          placeholder="供应商"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="激活号卡" prop="card">
        <el-input
          v-model="refundForm.card"
          placeholder="激活号卡"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="号卡类别" prop="cardType">
        <el-input
          v-model="refundForm.cardType"
          placeholder="号卡类别"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="订单金额(元)" prop="price">
        <el-input
          v-model="refundForm.price"
          placeholder="订单金额"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="退款金额(元)" prop="refund">
        <el-input
          v-model="refundForm.refund"
          placeholder="请输入退款金额"
        ></el-input>
      </el-form-item>
      <el-form-item label="退款类别" prop="tid">
        <el-select v-model="refundForm.tid" placeholder="请选择退款类别">
          <el-option
            v-if="refundType.type_list"
            v-for="item in refundType.type_list"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="扣费明细" style="width: 100%; height: auto">
        <div class="koufeiList">
          <el-button
            class="addBtn"
            icon="el-icon-plus"
            @click="addRow"
          ></el-button>
          <div
            class="row"
            v-if="refundForm.amount_list.length > 0"
            v-for="(item, index) in refundForm.amount_list"
          >
            <div class="left">
              <div><span style="color: #f56c6c">*</span>扣费项目</div>
              <el-input
                v-model="item.title"
                placeholder="请输入扣费项目"
              ></el-input>
            </div>
            <div class="right">
              <div><span style="color: #f56c6c">*</span>扣费金额(元)</div>
              <el-input
                v-model="item.amount"
                pattern="[0-9.]*"
                placeholder="请输入扣费金额"
              ></el-input>
            </div>
            <el-button
              class="minusBtn"
              icon="el-icon-minus"
              @click="minusRow(index)"
            ></el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="登记来源" prop="from">
        <el-select v-model="refundForm.from" placeholder="请选择登记来源">
          <el-option
            v-if="refundType.from_list"
            v-for="item in refundType.from_list"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="refundForm.phone"
          pattern="[0-9]*"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否注销号卡" prop="isCancel">
        <el-radio-group v-model="refundForm.isCancel">
          <el-radio label="是" value="1"></el-radio>
          <el-radio label="否" value="0"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="用户确认" prop="needConfirm">
        <el-radio-group v-model="refundForm.needConfirm">
          <el-radio label="不需要" value="0"></el-radio>
          <el-radio label="需要" value="1"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="desc" style="width: 100%">
        <el-input
          type="textarea"
          placeholder="请输入备注"
          v-model="refundForm.desc"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="resetForm('refundForm')">重置</el-button>
        <el-button type="primary" @click="submitForm('refundForm')">
          立即提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "refundLayer", // 登记退款
  props: {
    refundType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderInfo: {},
      refundForm: {
        tid: "",
        from: "",
        orderNo: "",
        channel: "",
        supplier: "",
        card: "",
        cardType: "",
        isCancel: "",
        price: "",
        refund: "",
        amount_list: [],
        phone: "",
        needConfirm: "",
        desc: "",
      },
      refundFormRules: {
        tid: [{ required: true, message: "请选择退款类别", trigger: "change" }],
        from: [
          { required: true, message: "请选择登记来源", trigger: "change" },
        ],
        orderNo: [
          { required: true, message: "请输入订单编号", trigger: "blur" },
        ],
        channel: [{ required: true, message: "暂无渠道", trigger: "blur" }],
        supplier: [{ required: true, message: "暂无供应商", trigger: "blur" }],
        card: [{ required: true, message: "暂无激活号卡", trigger: "blur" }],
        cardType: [
          { required: true, message: "暂无号卡类别", trigger: "blur" },
        ],
        isCancel: [
          { required: true, message: "请选择是否注销号卡", trigger: "change" },
        ],
        price: [{ required: true, message: "暂无订单金额", trigger: "blur" }],
        refund: [
          { required: true, message: "请输入退款金额", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              const pattern = /^\d+.?\d{0,2}$/;
              if (value > this.refundForm.price) {
                return callback(new Error("请输入合理的退款金额"));
              } else if (
                value <= this.refundForm.price &&
                !pattern.test(value)
              ) {
                return callback(new Error("请输入合理的退款金额"));
              } else {
                return callback();
              }
            },
            trigger: "blur",
          },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        needConfirm: [
          {
            required: true,
            message: "请选择是否需要用户确认",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  mounted() {},
  methods: {
    async getOrderInfo() {
      if (this.refundForm.orderNo) {
        const infoRes = await this.$zkHttp.interact$service$get_card_refund({
          order_num: this.refundForm.orderNo,
        });
        this.orderInfo = infoRes;
        this.refundForm.channel = infoRes.agent || "";
        this.refundForm.supplier = infoRes.maket || "";
        this.refundForm.card = infoRes.msisdn || "";
        this.refundForm.cardType = infoRes.card_class || "";
        this.refundForm.price = infoRes.amoutn || "";
      }
    },
    addRow() {
      this.refundForm.amount_list.push({ title: "", amount: "" });
    },
    minusRow(index) {
      this.refundForm.amount_list.splice(index, 1);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.refundForm.amount_list.length > 0) {
            let unFull = false;
            let amountTotal = 0;
            for (let i = 0; i < this.refundForm.amount_list.length; i++) {
              const item = this.refundForm.amount_list[i];
              amountTotal = amountTotal + (parseFloat(item.amount) * 100) / 100;
              if (!item.title || !item.amount) unFull = true;
            }
            if (unFull) {
              this.$message({
                message: "请将扣费明细填写完整!",
                type: "warning",
              });
              return;
            }
            if (
              amountTotal * 100 + parseFloat(this.refundForm.refund) * 100 >
              parseFloat(this.refundForm.price) * 100
            ) {
              this.$message({
                message: "扣费金额和退款金额已超出订单金额,请更正!",
                type: "warning",
              });
              return;
            }
          }
          const refundRes = await this.$zkHttp.interact$service$add_refund({
            kf_id: this.$store.state.auth.loginInfo.uid,
            order_num: this.refundForm.orderNo, // 订单号
            from_type: 9, //this.refundForm.from, // 来源id
            tid: this.refundForm.tid, // 退款类别id
            amount: this.refundForm.price, // 订单金额
            money: this.refundForm.refund, // 退款金额
            iccid: this.refundForm.card, // iccid 号卡
            tel: this.refundForm.phone,
            amount_list: this.refundForm.amount_list,
            cancel: this.refundForm.isCancel,
            is_check: this.refundForm.needConfirm,
            content: this.refundForm.desc,
            is_repeat: 0,
            phone_num: this.orderInfo.msisdn || "",
            ...this.orderInfo,
          });
          if (refundRes.status && refundRes.status === 1) {
            this.$confirm("已存在等级退款的提交记录,是否仍继续提交")
              .then(async () => {
                await this.$zkHttp.interact$service$add_refund({
                  kf_id: this.$store.state.auth.loginInfo.uid,
                  order_num: this.refundForm.orderNo, // 订单号
                  from_type: 9, //this.refundForm.from, // 来源id
                  tid: this.refundForm.tid, // 退款类别id
                  amount: this.refundForm.price, // 订单金额
                  money: this.refundForm.refund, // 退款金额
                  iccid: this.refundForm.card, // iccid 号卡
                  tel: this.refundForm.phone,
                  amount_list: this.refundForm.amount_list,
                  cancel: this.refundForm.isCancel,
                  is_check: this.refundForm.needConfirm,
                  content: this.refundForm.desc,
                  is_repeat: 1,
                  phone_num: this.orderInfo.msisdn || "",
                  ...this.orderInfo,
                });
                this.$message({
                  message: "登记退款继续提交成功！",
                  type: "success",
                });
                this.resetForm(formName);
              })
              .catch(() => {
                this.$message({
                  message: "已取消",
                  type: "info",
                });
              });
          } else {
            this.$message({
              message: "登记退款成功！",
              type: "success",
            });
            this.resetForm(formName);
          }
        } else {
          this.$message({
            message: "请完整且正确填写后提交！",
            type: "error",
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.refund {
  padding: 20px;
  width: calc(100% - 40px);
  display: none;
  .el-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .koufeiList {
    width: 100%;
    display: flex;
    flex-direction: column;
    .addBtn {
      width: 60px;
    }
    .row {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .left,
      .right {
        width: calc((100% - 40px) / 2);
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
          width: max-content;
          font-size: 12px;
        }
        .el-input {
          margin: 0 10px;
          flex: 1;
        }
      }
      .minusBtn {
        width: 40px;
        padding: 12px 2px;
      }
    }
  }
  ::v-deep .el-form-item {
    margin-bottom: 15px;
    width: 49%;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__error {
    padding-top: 2px;
  }
  ::v-deep .el-input-number {
    width: 100%;
  }
  ::v-deep .el-input-number__decrease,
  ::v-deep .el-input-number__increase {
    background: #fff;
  }
  ::v-deep .el-radio-group {
    width: 100%;
    text-align: left;
  }
}
@media screen and (max-width: 1500px) {
  ::v-deep .el-form-item {
    width: 100% !important;
  }
}
</style>
