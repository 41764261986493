import Clipboard from "clipboard";
import moment from "moment";
import Ed from "wangeditor";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
import { Base64 } from "js-base64";

class Lock {
  constructor() {
    this._locked = false;
  }

  lock(fn) {
    if (!this._locked) {
      this._locked = true;
      fn().finally(() => {
        this._locked = false;
      });
    } else {
      setTimeout(() => {
        this.lock(fn);
      }, 150);
    }
  }
}

const LOCK = new Lock();

export default {
  name: "indexView",
  data() {
    return {
      consoleLogs: [],
      encryptedStr: "",
      constObj: {
        link_htgl: "http://chat.wokahui.com/admin", // 后台管理
        link_htgl2: "http://chat.wokahui.com/service/login/ser_notify", // 后台管理
        link_zshk: "http://ad.geekunicom.com/#cardsearch/index.html", // 沃卡惠专属号卡管理系统
        link_hk: "http://admin.geekuicom.com/#/card_search/index.html", // 沃卡惠号卡管理系统
        link_lt: "https://free.wokahui.com/manage", // 联通沃卡惠
        msgTypeName: [
          "",
          "[图片]",
          "[视频]",
          "[补卡链接]",
          "[实名认证链接]",
          "[本小程序链接]",
        ], // 消息类型枚举
        overTime: 30,
      },
      linkRegExp:
        /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g, // 正则表达式用于匹配URL链接
      themes: this.$store.state.themes, // 主题数组
      selectedTheme: this.$store.state.selectedTheme, // 当前主题

      bgMusic: null,
      online: true, // 客服上线开关
      waitNum: 0, // 排队人数

      summary: {}, // 咨询类别
      serverTotal: 0, // 会话人数(暂未使用)
      userTotal: 0, // 已服务人数(暂未使用)
      showIndex: 0, // 当前显示的会话列表

      popoverWidth: (document.body.clientWidth - 220) / 2 - 24, // 中间区域popover宽度(表情,快捷用语)
      emojisVisible: false,
      phrasesVisible: false,
      phrases: [], // 快捷用语

      serverId: this.$store.state.auth.loginInfo.uid, // 客服id
      serverName: this.$store.state.auth.loginInfo.user_name, // 客服名
      serverAvatar: this.$store.state.auth.loginInfo.avatar, // 客服头像

      websocket: null,
      heartbeatTimer: null,
      userSet: [], // 会话用户ID.去重数组
      userList: [], // 会话用户数组
      endList: [], // 结束会话用户数组
      // userList item hook
      serveTimeTimer: null,
      overTimeTimer: null,
      selectedUserObj: {
        userId: 0, // 选中聊天用户id
        userName: "", // 选中聊天用户昵称
        userAvatar: "", // 选中聊天用户头像
        userInfo: {},
        matchInfo: {},
        carryIccid: "",
        msgPage: 1,
        msgList: [],
        newMsg: "",
        quoteItem: null,
        editorContent: "",
        serveTime: 0,
        overTime: 0,
        unRead: 0,
        replyNum: 0,
      }, // 选中聊天对象
      selectedUserObj_back: {}, // 切换聊天分组后缓存选择聊天对象

      showBigImagePreviewSrcList: [],
      editor: null,
      editorConfig: {
        hoverbarKeys: {
          image: {
            menuKeys: [],
          },
        },
        MENU_CONF: {
          //配置上传图片
          uploadImage: {
            // 自定义上传图片 方法
            customUpload: this.uploadImg,
            // server必须要配置正确,我这里因为上传图片有点特殊，在下面方法配置了，所以此处不用配置地址
            // server: "https://mini.wokahui.com/interact/upload/up_file",
            // 图片大小限制
            maxFileSize: 2 * 1024 * 1024, // 2M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 1,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],
          },
          // 配置上传视频（同上传图片）
          uploadVideo: {
            customUpload: this.uploadVideo,
            maxFileSize: 10 * 1024 * 1024,
            maxNumberOfFiles: 1,
            allowedFileTypes: ["video/*"],
          },
        },
      },

      checkOptions: [
        {
          title: "设置APN",
          num: 0,
        },
        {
          title: "验证卡芯",
          num: 0,
        },
        {
          title: "使用地区",
          num: 0,
        },
        {
          title: "使用设备",
          num: 0,
        },
      ],
      checkedOptions: [],
      iccid: "",
      iccidDrawer: false,
      iccidHeight: "-20px",

      refundType: {}, // 退款类别&来源
      typeList: {}, // 补卡类别和工单类别
      reasonList: [], // 补卡原因
      historyList: [], // 历史记录
      sheetList: [], // 工单记录
      orderList: [], // 订单记录
      refundList: [], // 退款记录
      compliantList: [], // 投诉记录

      phraseInfo: {},
      phraseEditDrawer: false,
      myCenterDrawer: false,
      myCenterInfo: {},
      serveCloseDrawer: false,
    };
  },
  watch: {
    selectedUserObj(newValue) {
      this.selectedUserObj = newValue;
    },
  },
  created() {
    // 鉴权加密
    // // https://blog.csdn.net/haonan_z/article/details/121559021
    // const encryptor = new JSEncrypt();
    // const publicKey =
    //   "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCavBs2Er6uMLnnKIIpnor8v3Dk" +
    //   "12LzV2yuGK6WIc9cs0Wg7rfxGu55LWsx+IKLTDllkYh3+3vQOfWuS+7IRIx5semF" +
    //   "8EW0ZRj87oNHajyezuKfwQeIsuzAt9CPql8U9Bh3ILfcZ8Sbks0sRwzwGt6reupY" +
    //   "gkfmZzbAD8j1UILQ3wIDAQAB";
    // const pwdStr = "tEUgfDp9hHEBIYPnMFInn1SuDkAnuh5D";
    // const urlencode = function (str) {
    //   str = (str + "").toString();
    //   return encodeURIComponent(str)
    //     .replace(/!/g, "%21")
    //     .replace(/'/g, "%27")
    //     .replace(/\(/g, "%28")
    //     .replace(/\)/g, "%29")
    //     .replace(/\*/g, "%2A")
    //     .replace(/%20/g, "+");
    // };
    // encryptor.setPublicKey(publicKey);
    // this.encryptedStr = Base64.encode(urlencode(encryptor.encrypt(pwdStr)));
    // console.log("encryptedStr", this.encryptedStr);
  },
  async mounted() {
    // 登录状态
    const localLoginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    console.log("localLoginInfo", localLoginInfo);
    if (
      localLoginInfo &&
      localLoginInfo.version === moment(Date.now()).format("YYYY-MM-DD") &&
      localLoginInfo.data.token
    ) {
      this.serverId = localLoginInfo.data.uid;
      this.serverName = localLoginInfo.data.user_name;
      this.serverAvatar = localLoginInfo.data.avatar;
      this.$store.commit("auth/setLoginInfo", {
        ...localLoginInfo.data,
      });
      // 聊天数据缓存
      const localIndexData = JSON.parse(localStorage.getItem("indexData"));
      console.log("localIndexData", localIndexData);
      if (
        localIndexData &&
        localIndexData.version === moment(Date.now()).format("YYYY-MM-DD") &&
        localIndexData.data.serverId === this.serverId
      ) {
        Object.assign(
          this.$data,
          JSON.parse(JSON.stringify(localIndexData.data))
        );
      }
      this.linkRegExp =
        /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      this.bgMusic = null;

      // 部分数据初始化
      setTimeout(() => {
        this.initWebSocket();
        this.getPhrases();
        this.getChatEndList();

        this.getTypeList();
        this.getReasonList();
        this.getRefundType();
      });
    } else {
      this.$router.replace("/login");
      return;
    }

    // 设置主题
    this.selectedTheme = localStorage.getItem("selectedTheme") || "default";
    this.onSwitchTheme(this.selectedTheme);

    const self = this;

    this.serveTimeTimer = setInterval(() => {
      if (self.userList && self.userList.length > 0) {
        for (let i = 0; i < self.userList.length; i++) {
          self.userList[i].serveTime++;
        }
      }
      if (self.selectedUserObj && self.selectedUserObj.userId) {
        self.selectedUserObj.serveTime++;
      }
    }, 1000);
    this.overTimeTimer = setInterval(() => {
      if (self.userList && self.userList.length > 0) {
        for (let i = 0; i < self.userList.length; i++) {
          if (
            0 < self.userList[i].overTime &&
            self.userList[i].overTime < self.constObj.overTime
          ) {
            self.userList[i].overTime++;
          }
        }
      }
      if (self.selectedUserObj && self.selectedUserObj.userId) {
        if (
          0 < self.selectedUserObj.overTime &&
          self.selectedUserObj.overTime < self.constObj.overTime
        ) {
          self.selectedUserObj.overTime++;
        }
      }
    }, 1000);
    if (this.selectedUserObj && this.selectedUserObj.userId) {
      // 初始化数据,onSwitchUser
      console.log("this.selectedUserObj111", this.selectedUserObj);
      const uInfoRes = await this.getUserInfo(this.selectedUserObj.userId);
      const uMsgRes = await this.getWsMsg(this.selectedUserObj.userId);
      this.selectedUserObj.userId = uInfoRes.id;
      this.selectedUserObj.userName = uInfoRes.name;
      this.selectedUserObj.userAvatar = uInfoRes.avatar;
      this.selectedUserObj.userInfo = uInfoRes;
      this.selectedUserObj.msgList = uMsgRes.list || [];
      console.log("this.selectedUserObj222", this.selectedUserObj);
      this.onSwitchUser(this.selectedUserObj); // 以上数据不放人入是为了减少接口调用
    }
    // 监听
    window.onresize = () => {
      return (() => {
        self.popoverWidth = (document.body.clientWidth - 220) / 2 - 44;
      })();
    };
    document.onkeydown = () => {
      const e = window.event || event;
      if (e.shiftKey && e.keyCode === 13) {
        return;
      }
      if (e.keyCode === 13) {
        e.preventDefault();
        console.log("editorContent", this.selectedUserObj.editorContent);
        let editorContent = this.selectedUserObj.editorContent;
        if (editorContent !== "" && editorContent !== "<p><br></p>") {
          console.log("html 0=>", editorContent);
          editorContent = editorContent.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
          editorContent = editorContent.replace(
            /<style>[\s\S]*?<\/style>/gi,
            ""
          );
          editorContent = editorContent.replace(/ style="[\s\S]*?"/gi, ""); // 去除标签style

          // editorContent = editorContent.replace(/<xxxx[^>]+>/g, ""); // 去除特定标签

          editorContent = editorContent.replace(/&nbsp;/gi, ""); // 剔除空格
          editorContent = editorContent.replace(/(\r\n|\n|\r)/gm, ""); // 剔除首尾的换行

          console.log("1=>", editorContent);
          if (editorContent) {
            this.sendWxMsg(0, editorContent); // 消息类别:0字符串,1图片,2视频,3补卡连接,4实名,5本小程序的链接
          }
        }
      }
    };
    // 监听
    window.addEventListener("click", () => this.onSimulated());
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler());

    this.initEditor();
  },
  onUpdated() {},
  beforeDestroy() {
    console.log("index beforeDestroy");
    if (this.editor) {
      this.editor.destroy(); // 组件销毁时，及时销毁编辑器
    }
    if (this.websocket) {
      this.websocket.close();
    }
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
    }
    if (this.serveTimeTimer) {
      clearInterval(this.serveTimeTimer);
    }
    if (this.overTimeTimer) {
      clearInterval(this.overTimeTimer);
    }
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler()
    );
  },
  methods: {
    consoleLog(...args) {
      console.log(...args);
      if (this.consoleLogs.length < 20) {
        this.consoleLogs.push({
          time: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          log: JSON.stringify(args),
        });
      } else {
        // 可以选择保留最新的元素或者其他策略
        // 这里我们选择移除最前面的元素，让新元素进入数组
        this.consoleLogs.shift();
        this.consoleLogs.push({
          time: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          log: JSON.stringify(args),
        });
      }
    },
    // 页面销毁前,进行数据持久化
    beforeunloadHandler() {
      console.log(
        "beforeunloadHandler this.userSet:",
        this.userSet,
        "beforeunloadHandler this.userList:",
        this.userList,
        "this.selectedUserObj:",
        this.selectedUserObj
      );
      if (!this.userList || this.userList.length === 0) {
        this.selectedUserObj = {};
        this.userList = [];
        this.userSet = [];
      }
      localStorage.setItem(
        "indexData",
        JSON.stringify({
          version: moment(Date.now()).format("YYYY-MM-DD"),
          data: {
            serverId: this.serverId,
            selectedUserObj: this.selectedUserObj,
            userList: this.userList,
            userSet: this.userSet,
          },
        })
      );
    }, // 清除数据持久化
    clearLocalStorage() {
      Object.assign(this.$data, this.$options.data.call(this));
      localStorage.removeItem("indexData");
      this.$message({
        message: "缓存已清除！",
        type: "success",
      });
      setTimeout(() => this.$router.go(0), 300);
    },
    // 主题切换
    onSwitchTheme(theme) {
      this.selectedTheme = theme;
      this.beforeunloadHandler();
      console.log("selectedTheme:", this.selectedTheme);
      this.$store.commit("setSelectedTheme", this.selectedTheme);
      this.$setTheme(this.selectedTheme);
    },
    // 客服上下线开关
    async setServeStatus(e) {
      console.log("setServeStatus:", e);
      if (e) {
        const onlineRes = await this.$zkHttp.interact$index$set_action({
          kf_id: this.serverId,
          action_type: 1, // 1:上线,2:离线,3:暂停
        });
        this.$message({
          message: "恢复接入！",
          type: "success",
        });
        setTimeout(() => this.$router.go(0), 300);
      } else {
        const leaveRes = await this.$zkHttp.interact$index$set_action({
          kf_id: this.serverId,
          action_type: 2, // 1:上线,2:离线,3:暂停
        });
        this.websocket.send(
          JSON.stringify({
            type: "beforeLive",
            group: this.$store.state.auth.loginInfo.group,
            uid: this.serverId,
          })
        );
        this.$message({
          message: "暂停接入！",
          type: "info",
        });
      }
      this.online = e;
    },
    // 个人中心
    async myCenterDrawerOpen() {
      this.myCenterDrawer = true;
      this.myCenterInfo = await this.$zkHttp.interact$index$personal({
        kf_id: this.serverId,
        group: this.$store.state.auth.loginInfo.group,
      });
    },
    myCenterDrawerClose() {
      this.myCenterDrawer = false;
    },
    websocketClose() {
      this.websocket.send(
        JSON.stringify({
          type: "loginOut",
          uid: this.$store.state.auth.loginInfo.uid,
          group: this.$store.state.auth.loginInfo.group,
        })
      );
    },
    // 退款登记
    refundOpen() {
      const self = this;
      layer.open({
        title: "退款登记",
        id: "refundLayer",
        type: 1,
        offset: "rt",
        area: ["40vw", "100%"],
        shade: false, // 遮罩透明度
        shadeClose: false, // 点击遮罩区域，关闭弹层
        maxmin: true, // 允许全屏最小化
        resize: true, // 否允许拖拽弹层右下角拉伸尺寸
        scrollbar: false, // 打开弹层时，是否允许浏览器出现滚动条
        anim: 0, // 0-6 的动画形式，-1 不开启
        content: $("#refund"),
        cancel(index, layero) {
          self.$refs.refund.resetForm("refundForm");
        },
      });
    },
    // 全屏
    setFullScreen() {
      //===全屏===
      if (document.documentElement.RequestFullScreen) {
        document.documentElement.RequestFullScreen();
      }
      //兼容火狐
      if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      }
      //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
      if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      }
      //兼容IE,只能写msRequestFullscreen
      if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      //===取消全屏===
      if (document.exitFullScreen) {
        document.exitFullscreen();
      }
      //兼容火狐
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      //兼容谷歌等
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      //兼容IE
      if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    // 富文本编辑器初始化
    initEditor() {
      this.editor = new Ed(this.$refs.myEditor);
      console.log("myEditor", this.editor);

      this.editor.config.showFullScreen = false;
      this.editor.config.menus = [];
      this.editor.config.placeholder = "请输入...";

      this.editor.config.customUploadImg = this.uploadImg;
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 2M
      this.editor.config.uploadImgMaxLength = 1; // 最多可上传几个文件，默认为 100
      this.editor.config.uploadImgAccept = ["image/*"]; // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []

      this.editor.config.customUploadVideo = this.uploadVideo;
      this.editor.config.customUploadImg = this.uploadImg;
      this.editor.config.uploadVideoMaxSize = 10 * 1024 * 1024; // 10M
      this.editor.config.uploadVideoMaxLength = 1; // 最多可上传几个文件，默认为 100
      this.editor.config.uploadVideoAccept = ["video/*"]; // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []

      this.editor.config.onchange = (html) => {
        console.log("this.editor.config.onChange", html);
        this.selectedUserObj.editorContent = html;
      };

      this.editor.create();
    },
    onEditorCustomPaste(editor, event) {
      try {
        let html = event.clipboardData.getData("text/html"); // 获取粘贴的 html
        if (html) {
          console.log("html 0=>", html);
          html = html.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
          html = html.replace(/<style>[\s\S]*?<\/style>/gi, "");
          html = html.replace(/ style="[\s\S]*?"/gi, ""); // 去除标签style

          // 定义HTML5标签的正则表达式
          const html5Tags =
            /^<(a|abbr|address|area|article|aside|audio|b|base|bdi|bdo|blockquote|br|button|canvas|cite|code|col|colgroup|data|datalist|del|dfn|div|dl|em|embed|fieldset|figure|footer|form|h1|h2|h3|h4|h5|h6|head|header|hgroup|hr|html|i|iframe|img|input|ins|kbd|keygen|label|legend|li|link|main|map|mark|math|menu|meta|meter|nav|noscript|object|ol|optgroup|option|output|p|param|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|source|span|strong|style|sub|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|u|ul|var|video|wbr)(?:\s|[^\s<]+|(<[\/\!]*\1\s*>))*?\/?>$/i;

          // 使用正则表达式替换非HTML5标签
          html = html.replace(/<([^>]+)>/g, function (match, p1) {
            if (!html5Tags.test(p1)) {
              return ""; // 返回空字符串替换掉非HTML5标签
            }
            return match; // 保留HTML5标签
          });

          html = html.replace(/&nbsp;/gi, ""); // 剔除空格
          html = html.replace(/(\r\n|\n|\r)/gm, ""); // 剔除首尾的换行

          console.log("1=>", html);
          editor.setHtml(html);

          event.preventDefault(); // 阻止默认的粘贴行为
          return false;
        }
      } catch (e) {
        console.log("text err=>", e);
      }
      try {
      } catch (e) {
        console.log("html err=>", e);
      }
    },
    // 解决不能直接播放声音文件问题,监听点击模拟人为触发
    onSimulated() {
      if (!this.bgMusic) {
        let audio = new Audio();
        audio.src = require("@/assets/audio/3.mp3");
        audio.muted = true;
        this.bgMusic = audio;
        this.bgMusic.play();
      }
    },
    playBgMusic() {
      if (this.bgMusic) {
        this.bgMusic.pause();
        this.bgMusic.currentTime = 0;
        this.bgMusic.muted = false;
        this.bgMusic.play();
      }
    },

    // iccid查询信息
    onSearchIccid(row) {
      const iccid = (row && row.iccid) || this.iccid;
      if (iccid) {
        const url =
          "https://admin.geekuicom.com/card_search/index.html?iccid=" +
          iccid +
          "&token=f39d2881b822d99eba9f46f42a124651";
        layer.open({
          title: "ICCID详情",
          id: "iccidDetail",
          type: 1,
          offset: "rt",
          area: ["1080px", "100%"],
          shade: false, // 遮罩透明度
          shadeClose: false, // 点击遮罩区域，关闭弹层
          maxmin: true, // 允许全屏最小化
          resize: true, // 否允许拖拽弹层右下角拉伸尺寸
          scrollbar: false, // 打开弹层时，是否允许浏览器出现滚动条
          anim: 0, // 0-6 的动画形式，-1 不开启
          content: `
            <div style="width: 1465px;height: 1192px;padding: 20px;">
              <iframe style="width: 100%;height: 100%;border: none;" src="${url}"></iframe>
            </div>
          `,
        });

        // this.iccidDrawer = true;
        // setTimeout(() => {
        //   let myIframe = document.getElementById("myIframe");
        //   myIframe.setAttribute("src", url);
        // }, 150);
      }
    },
    iccidDrawerClose() {
      this.iccidDrawer = false;
    },
    onCopyIccid() {
      let clipboard = new Clipboard("#copyBtn");
      const self = this;
      clipboard.on("success", (e) => {
        self.$message({
          message: "已复制！",
          type: "success",
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        self.$message({
          message: "该浏览器不支持！",
          type: "fail",
        });
        clipboard.destroy();
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (!this.selectedUserObj.carryIccid) {
        return rowIndex === 0 ? "highlight-row" : "";
      } else if (row.iccid === this.selectedUserObj.carryIccid) {
        return "highlight-row";
      } else {
        return "";
      }
    },
    showAll() {
      if (this.iccidHeight === "150px") {
        this.iccidHeight = "max-content";
      } else {
        this.iccidHeight = "150px";
      }
    },

    // =========用户数据初始化=========
    // 获取补卡类别和工单类别
    async getTypeList() {
      const typeRes = await this.$zkHttp.interact$index$sheet_type_list({
        kf_id: this.serverId,
      });
      this.typeList = typeRes;
    },
    // 获取补卡原因
    async getReasonList() {
      const reasonRes = await this.$zkHttp.interact$service$get_reason({
        kf_id: this.serverId,
      });
      this.reasonList = reasonRes;
    },
    // 获取退款类别&来源
    async getRefundType() {
      const refundTypeRes =
        await this.$zkHttp.interact$service$get_refund_type();
      this.refundType = refundTypeRes;
    },
    // 获取用户排查项
    async getUserChecked() {
      let iccid = "";
      let uid = "";
      uid = this.selectedUserObj.userId;
      iccid = this.selectedUserObj.carryIccid;
      if (!iccid) {
        iccid = this.selectedUserObj.userInfo.iccid
          ? JSON.parse(this.selectedUserObj.userInfo.iccid)[0]["iccid"]
          : "";
      }
      if (iccid) {
        const checkedRes = await this.$zkHttp.interact$service$getChecksUid({
          uid,
          iccid,
        });
        console.log("checkedRes", checkedRes);
        this.checkedOptions = checkedRes || [];
        if (this.checkedOptions.length > 0) {
          for (let i = 0; i < this.checkOptions.length; i++) {
            this.checkOptions[i].num = 0;
            for (let j = 0; j < this.checkedOptions.length; j++) {
              if (this.checkOptions[i].title === this.checkedOptions[j]) {
                this.checkOptions[i].num += 1;
              }
            }
          }
        }
      }
    },
    // 设置用户排查项
    async setUserChecked(e) {
      let iccid = this.selectedUserObj.carryIccid;
      if (
        !iccid &&
        this.selectedUserObj.userInfo &&
        this.selectedUserObj.userInfo.iccid
      ) {
        iccid = JSON.parse(this.selectedUserObj.userInfo.iccid)[0]["iccid"];
      }
      if (!iccid) {
        this.$message({
          message: "无ICCID不需要排查！",
          type: "fail",
        });
        return;
      }
      if (iccid && this.checkedOptions.indexOf(e.title) < 0) {
        const checkedRes = await this.$zkHttp.interact$service$addcheck({
          kf_id: this.serverId,
          uid: this.selectedUserObj.userId,
          title: e.title,
          action: "add",
          iccid,
        });
        console.log("checkedRes", checkedRes);
        this.$message({
          message: "已排查",
          type: "success",
        });
        this.getUserChecked();
      }
    },
    // 获取历史记录
    async getUserHistoryList() {
      const historyRes = await this.$zkHttp.interact$index$get_service_log({
        uid: this.selectedUserObj.userId,
      });
      this.historyList = historyRes;
    },
    // 工单
    async getUserSheetList() {
      const sheetRes = await this.$zkHttp.interact$index$get_sheets({
        uid: this.selectedUserObj.userId,
      });
      this.sheetList = sheetRes;
    },
    // 订单
    async getUseOrderList() {
      const orderRes = await this.$zkHttp.interact$index$get_saleds({
        uid: this.selectedUserObj.userId,
      });
      this.orderList = orderRes;
    },
    // 退款
    async getUseRefundList() {
      const refundRes = await this.$zkHttp.interact$index$get_drawback({
        from_node: this.selectedUserObj.userInfo.from_node,
      });
      this.refundList = refundRes;
    },
    // 投诉
    async getUserCompliantList() {
      const compliantRes = await this.$zkHttp.interact$index$get_compliant({
        uid: this.selectedUserObj.userId,
      });
      this.compliantList = compliantRes;
    },

    // =========以下是聊天相关=========
    // 聊天列表切换
    async onSwitchChatList(index) {
      console.log("onSwitchChatList:", index);
      this.showIndex = index;
      switch (this.showIndex) {
        case 0:
          if (this.userList && this.userList.length > 0) {
            if (this.selectedUserObj_back && this.selectedUserObj_back.userId) {
              const backObj = this.selectedUserObj_back;
              this.selectedUserObj_back = JSON.parse(
                JSON.stringify(this.selectedUserObj)
              );
              await this.onSwitchUser(JSON.parse(JSON.stringify(backObj)));
            } else {
              await this.onSwitchUser(
                JSON.parse(JSON.stringify(this.userList[0]))
              );
            }
          } else {
            this.selectedUserObj = {};
            await this.initUserData();
          }
          break;
        case 1:
          await this.getChatEndList();
          break;
        default:
          break;
      }
      console.log("this.selectedUserObj:", this.selectedUserObj);
    },
    // 获取结束聊天列表
    async getChatEndList() {
      const endRes = await this.$zkHttp.interact$index$get_over_member({
        kf_id: this.serverId,
      });
      this.endList = [];
      if (endRes && endRes.length > 0) {
        for (let i = 0; i < endRes.length; i++) {
          this.endList.push({
            userId: endRes[i].uid,
            userName: endRes[i].name,
            userAvatar: endRes[i].avatar,
            userInfo: {},
            matchInfo: {},
            carryIccid: "",
            msgPage: 1,
            msgList: [],
            newMsg: "",
            quoteItem: null,
            editorContent: "",
            serveTime: 0,
            overTime: 0,
            unRead: 0,
            replyNum: 0,
          });
        }
      }
      if (this.showIndex === 1) {
        if (this.endList && this.endList.length > 0) {
          if (this.selectedUserObj_back && this.selectedUserObj_back.userId) {
            const backObj = { ...this.selectedUserObj_back };
            this.selectedUserObj_back = JSON.parse(
              JSON.stringify(this.selectedUserObj)
            );
            await this.onSwitchEnd(JSON.parse(JSON.stringify(backObj)));
          } else {
            await this.onSwitchEnd(endRes[0]);
          }
        } else {
          this.selectedUserObj = {};
          await this.initUserData();
        }
      }
    },
    // 查询用户信息
    async getUserInfo(uid) {
      const userRes = await this.$zkHttp.interact$index$get_user_info({
        uid,
      });
      console.log("userRes", userRes);
      const iccid = this.selectedUserObj.carryIccid;
      if (iccid && userRes && userRes.iccid) {
        // 如果用户带了iccid,把它放到列表第一个来
        let iccidArr = JSON.parse(userRes.iccid);
        for (let i = 0; i < iccidArr.length; i++) {
          const iccidItem = iccidArr[i];
          if (iccidItem.iccid === iccid) {
            iccidArr.splice(i, 1);
            iccidArr.unshift(iccidItem);
            userRes.iccid = JSON.stringify(iccidArr);
          }
        }
      }
      // TODO: 涉嫌诈骗逻辑待完善
      return userRes;
    },
    // 聊天配对信息
    async matchWsChat(e) {
      const serverInfo = this.$store.state.auth.loginInfo;
      const matchRes = await this.$zkHttp.interact$index$add_service({
        uid: e.uid,
        name: e.name,
        avatar: e.avatar,
        group_id: serverInfo.group,
        kf_id: serverInfo.uid,
        kf_name: serverInfo.user_name,
      });
      return matchRes;
    },
    // 获取聊天历史记录
    async getWsMsg(uid) {
      if (!uid) return {};
      this.selectedUserObj.msgPage = 1;
      const msgRes = await this.$zkHttp.interact$index$get_chat_log({
        uid,
        page: this.selectedUserObj.msgPage,
      });
      msgRes.list = msgRes.list.sort((a, b) => {
        return new Date(a.time_line) - new Date(b.time_line);
      });
      return msgRes;
    },
    // 更多聊天历史记录
    async getMoreWsMsg() {
      this.selectedUserObj.msgPage = this.selectedUserObj.msgPage + 1;
      const msgRes = await this.$zkHttp.interact$index$get_chat_log({
        uid: this.selectedUserObj.userId,
        page: this.selectedUserObj.msgPage,
      });
      if (msgRes.list.length > 0) {
        msgRes.list = msgRes.list.sort((a, b) => {
          return new Date(a.time_line) - new Date(b.time_line);
        });
        this.selectedUserObj.msgList = msgRes.list.concat(
          this.selectedUserObj.msgList
        );
        this.toMsgItem(msgRes.list[msgRes.list.length - 1]);
      } else {
        this.selectedUserObj.msgPage = this.selectedUserObj.msgPage - 1;
        this.$message({
          message: "所有历史消息已加载完毕！",
          type: "info",
        });
      }
    },
    // 数据初始化
    clearRightData() {
      this.checkOptions = [
        {
          title: "设置APN",
          num: 0,
        },
        {
          title: "验证卡芯",
          num: 0,
        },
        {
          title: "使用地区",
          num: 0,
        },
        {
          title: "使用设备",
          num: 0,
        },
      ];
      this.historyList = [];
      this.sheetList = [];
      this.orderList = [];
      this.refundList = [];
      this.compliantList = [];
    },
    // 数据初始化
    initUserData() {
      this.clearRightData();
      if (this.selectedUserObj && this.selectedUserObj.userId) {
        Promise.all([
          this.getUserChecked(),
          this.getUserHistoryList(),
          this.getUserSheetList(),
          this.getUseOrderList(),
          this.getUseRefundList(),
          this.getUserCompliantList(),
        ]);
      }
    },
    // 用户切换
    async onSwitchUser(e) {
      console.log("onSwitchUser: ", e);
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].userId === this.selectedUserObj.userId) {
          this.userList[i] = JSON.parse(JSON.stringify(this.selectedUserObj));
        }
      }
      // 切换用户不需要获取历史消息，本地维护有消息列表
      // const uMsgRes = await this.getWsMsg(e.userId);
      // e.msgList = uMsgRes.list || [];
      if (e.userId !== this.selectedUserObj.userId) {
        setTimeout(() => {
          this.selectedUserObj = JSON.parse(JSON.stringify(e));
          this.initUserData();
          this.iccidHeight =
            this.selectedUserObj.userInfo && this.selectedUserObj.userInfo.iccid
              ? ["62px", "106px", "150px"][
                  JSON.parse(this.selectedUserObj.userInfo.iccid).length >= 3
                    ? "2"
                    : JSON.parse(this.selectedUserObj.userInfo.iccid).length
                ]
              : "-20px";
          this.toMsgBottom();
        });
      } else {
        // this.initUserData();
        // else测试逻辑
        // this.getUserChecked();
        // this.getUserHistoryList();
        // this.getUserSheetList();
        // this.getUseOrderList();
        // this.getUseRefundList();
        // this.getUserCompliantList();
      }
    },
    // 结束用户切换
    async onSwitchEnd(e) {
      console.log("onSwitchEnd: ", e);
      if (e && e.uid) {
        const uInfoRes = await this.getUserInfo(e.uid);
        const uMsgRes = await this.getWsMsg(e.uid);
        const uObj = {
          userId: uInfoRes.id,
          userName: uInfoRes.name,
          userAvatar: uInfoRes.avatar,
          userInfo: uInfoRes,
          matchInfo: {},
          msgPage: 1,
          msgList: uMsgRes.list || [],
          newMsg:
            uMsgRes.list && uMsgRes.list.length > 0
              ? uMsgRes.list[uMsgRes.list.length - 1].content
              : "",
          quoteItem: null,
          editorContent: "",
          serveTime: 0,
          overTime: 0,
          unRead: 0,
        };
        this.selectedUserObj = JSON.parse(JSON.stringify(uObj));
        this.iccidHeight =
          this.selectedUserObj.userInfo && this.selectedUserObj.userInfo.iccid
            ? ["62px", "106px", "150px"][
                JSON.parse(this.selectedUserObj.userInfo.iccid).length >= 3
                  ? "2"
                  : JSON.parse(this.selectedUserObj.userInfo.iccid).length
              ]
            : "-20px";
        this.toMsgBottom();
        // TODO：已结束用户处理
        this.initUserData();
      }
    },
    // 初始化聊天通道
    initWebSocket() {
      const token = this.$store.state.auth.loginInfo.token;
      // const token = "830f13557e5ad29fb43ff3b99d45d724";
      const wsHost = `${
        this.$store.state.host.wsHost[this.$store.state.host.env]
      }?token=${token}`;
      console.log("wsHost：", wsHost);
      // 建立websocket连接
      this.websocket = new WebSocket(wsHost);
      // 打开websocket
      this.websocket.onopen = this.onWsOpen;
      // websocket连接错误时重连
      this.websocket.onerror = this.onWsError;
      // websocket接收信息
      this.websocket.onmessage = this.onWsMessage;
    },
    async onWsOpen() {
      console.log("连接成功");
      // 登录
      const serverInfo = this.$store.state.auth.loginInfo;
      const loginData = {
        type: "init",
        uid: serverInfo.uid,
        name: serverInfo.user_name,
        avatar: serverInfo.avatar,
        group: serverInfo.group,
        role: serverInfo.role,
        max: serverInfo.max_num,
        level: serverInfo.level,
      };
      this.websocket.send(JSON.stringify(loginData));
      this.wsHeartbeat();
      await this.$zkHttp.chat$index$setUserOnline({
        uid: this.serverId,
      });
      this.$message({
        message: "对话连接成功！",
        type: "success",
      });
      this.toMsgBottom();
    },
    onWsError(err) {
      console.log("onWsError:", err);
      //连接建立失败重连
      //this.initWebSocket();
    },
    wsHeartbeat() {
      if (!this.heartbeatTimer) {
        const self = this;
        this.heartbeatTimer = setInterval(() => {
          self.websocket.send('{"type":"pong"}');
          //$.get('/service/index/getlogin');
        }, 30 * 1000);
      } else {
        clearInterval(this.heartbeatTimer);
      }
    },
    async onWsMessage(e) {
      //数据接收，首先要解析服务器传过来的数据
      const wsMsgData = JSON.parse(e.data);
      // console.log("onWsMessage:", wsMsgData);
      switch (wsMsgData.type) {
        // 服务端ping客户端
        case "ping":
          console.log("onWsMessage: ping");
          this.websocket.send('{"type":"pong"}');
          break;
        case "init":
          console.log("onWsMessage: init");
          this.zkHttp.chat$chat$bind(wsMsgData);
          break;
        case "sysRefuse":
          console.log("onWsMessage: sysRefuse");
          this.$router.replace("/login");
          break;
        case "sysSend":
          console.log("onWsMessage: sysSend");
          break;
        case "sayHi": // 客服上线了
          console.log("onWsMessage: sayHi:", wsMsgData);
          await this.pushUserOrMsg(wsMsgData);
          break;
        case "chatMessage": // 来消息了
          console.log("onWsMessage: chatMessage:", wsMsgData);
          setTimeout(async () => {
            await this.pushUserOrMsg(wsMsgData);
          }, 500);
          break;
        case "timeOut":
          console.log("onWsMessage: timeOut");
          break;
        case "totals":
          console.log("onWsMessage: totals");
          this.serverTotal = wsMsgData.kf_num;
          this.userTotal = wsMsgData.user_num;
          break;
        case "rechangeManage": // 内部转接的回执
          console.log("onWsMessage: rechangeManage");
          if (wsMsgData.code === 3) {
            this.changeOverSuccess(wsMsgData);
          } else {
            this.$message({
              message: wsMsgData.msg,
              type: "error",
            });
          }
          break;
        case "rechangeGroup": // 转组的回执
          console.log("onWsMessage: rechangeGroup");
          if (wsMsgData.code === 3) {
            this.changeOverWhSuccess(wsMsgData);
          } else {
            this.$message({
              message: wsMsgData.msg,
              type: "error",
            });
          }
          break;
        case "waitUser":
          console.log("onWsMessage: waitUser");
          break;
        case "nowData":
          // clicks:小程序人工客服点击数;group:分组id;hander:服务人数;nums:消息数;wait:排队人数
          if (wsMsgData.data.group === this.$store.state.auth.loginInfo.group) {
            // console.log("onWsMessage: nowData:", wsMsgData);
            this.waitNum = wsMsgData.data.wait || 0;
          }
          break;
        case "checkedOnline":
          console.log("onWsMessage: checkedOnline");
          console.log(`用户${wsMsgData.uid}已经离线了!`);
          // this.$message({
          //   message: `用户${wsMsgData.uid}已经离线了!`,
          //   type: "error",
          // });
          break;
        case "userClose":
          console.log("onWsMessage: userClose");
          this.setUserClose(wsMsgData);
          break;
        case "delUser":
          console.log("onWsMessage: delUser");
          this.doDelClose(wsMsgData);
          break;
        case "lookKf":
          console.log("onWsMessage: lookKf");
          break;
        case "getKfs":
          console.log("onWsMessage: getKfs");
          break;
        case "mail":
          console.log("onWsMessage: mail");
          break;
        case "warning":
          // console.log("onWsMessage: warning:");
          // try {
          //   this.$zkHttp.chat$index$wait_warn(wsMsgData);
          // } catch (e) {}
          break;
        case "ShowIccid":
          console.log("onWsMessage: ShowIccid");
          this.wxIccidListen(wsMsgData);
          break;
        case "ack":
          console.log("onWsMessage: ack");
          this.setUserAck(wsMsgData);
          break;
      }
    },
    async pushUserOrMsg(wsMsgData) {
      this.consoleLog("pushUserOrMsg", wsMsgData);
      LOCK.lock(async () => {
        this.consoleLog("pushUserOrMsg lock START");
        await new Promise(async (resolve) => {
          let user = {};
          if (wsMsgData.type === "sayHi") {
            user = wsMsgData.user;
          } else if (wsMsgData.type === "chatMessage") {
            user = {
              name: wsMsgData.data.name,
              avatar: wsMsgData.data.avatar,
              newMsg: wsMsgData.data.content,
              uid: wsMsgData.from_id,
            };
          }
          if (user.uid) {
            let hasUserIndex = -1;
            for (let i = 0; i < this.userSet.length; i++) {
              if (this.userSet[i] === user.uid) {
                hasUserIndex = i;
              }
            }
            if (hasUserIndex < 0) {
              this.consoleLog("this.userSet0", this.userSet);
              this.userSet.push(user.uid);
              this.consoleLog("this.userSet1", this.userSet);

              const uMatchRes = await this.matchWsChat(user);
              const uInfoRes = await this.getUserInfo(user.uid);
              const uMsgRes = await this.getWsMsg(user.uid);

              // userList item hook
              let uObj = {
                userId: uInfoRes.id,
                userName: uInfoRes.name,
                userAvatar: uInfoRes.avatar,
                userInfo: uInfoRes,
                matchInfo: uMatchRes,
                msgPage: 1,
                msgList: uMsgRes.list || [],
                newMsg: "",
                quoteItem: null,
                editorContent: "",
                serveTime: 0,
                overTime: 0,
                unRead: 0,
                replyNum: 0,
              };
              setTimeout(() => {
                this.consoleLog("this.userList0", this.userList);
                // this.userList.push(uObj);
                this.userList.unshift(uObj);
                this.consoleLog("this.userList1", this.userList);

                if (this.userList.length === 1) {
                  this.selectedUserObj = JSON.parse(JSON.stringify(uObj));
                  this.iccidHeight =
                    this.selectedUserObj.userInfo &&
                    this.selectedUserObj.userInfo.iccid
                      ? ["62px", "106px", "150px"][
                          JSON.parse(this.selectedUserObj.userInfo.iccid)
                            .length >= 3
                            ? "2"
                            : JSON.parse(this.selectedUserObj.userInfo.iccid)
                                .length
                        ]
                      : "-20px";
                  this.toMsgBottom();
                  this.initUserData();
                }
              }, 0);

              if (wsMsgData.type === "sayHi") {
                this.playBgMusic();
              } else if (wsMsgData.type === "chatMessage") {
                // 消息入库后才推送到端，首次进入的情况不做消息插入
                // await this.handleWsMsg(wsMsgData);
              }
            } else {
              if (wsMsgData.type === "sayHi") {
                this.userList[hasUserIndex].newMsg = "在线";
              } else if (wsMsgData.type === "chatMessage") {
                await this.handleWsMsg(wsMsgData);
              }
            }

            setTimeout(async () => {
              this.consoleLog("pushUserOrMsg lock END", user.uid);
              resolve();
            }, 150);
          }
        });
      });
    },
    async handleWsMsg(e) {
      this.consoleLog("handleWsMsg", e);
      await new Promise(async (resolve) => {
        switch (e.data.msgtype) {
          case 0:
            break;
          case 1:
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            break;
          case 5:
            break;
          default:
            break;
        }
        let readyIndex = -1;
        if (this.userList && this.userList.length > 0) {
          for (let i = 0; i < this.userList.length; i++) {
            const userItem = this.userList[i];
            if (userItem.userId === e.from_id) {
              readyIndex = i;
              let quote_data = null;
              let quote_id = "";
              if (e.data.quote_id) {
                for (let j = 0; j < this.userList[i].msgList.length; j++) {
                  const msg = this.userList[i].msgList[j];
                  if (e.data.quote_id == msg.id) {
                    quote_data = msg;
                    quote_id = e.data.quote_id;
                  }
                }
              }
              const nObj = Date.now();
              const newMsgObj = {
                content: e.data.content || "",
                from_avatar: e.data.avatar || "",
                from_id: e.from_id || 0,
                from_name: e.data.name || "",
                id: e.data.mid || "",
                is_out: "",
                is_ready: "",
                msgtype: e.data.msgtype || 0,
                quote_data,
                quote_id,
                status: 0,
                time_line: `${moment(nObj).format("YYYY-MM-DD")} ${
                  e.data.time
                }`,
                to_id: e.to_id || 0,
                to_name: "",
              };
              if (
                userItem.msgList &&
                userItem.msgList.length >= 0 &&
                userItem.msgList[userItem.msgList.length - 1]["id"] !==
                  newMsgObj.id
              ) {
                this.userList[i].newMsg = e.data.content || "在线";
                this.userList[i].unRead = this.userList[i].unRead + 1;
                this.userList[i].overTime = 1;
                userItem.msgList.push(newMsgObj);
              }
              this.userList[i] = userItem;

              if (this.selectedUserObj.userId === userItem.userId) {
                if (
                  this.selectedUserObj.msgList &&
                  this.selectedUserObj.msgList.length >= 0 &&
                  this.selectedUserObj.msgList[
                    this.selectedUserObj.msgList.length - 1
                  ]["id"] !== newMsgObj.id
                ) {
                  this.selectedUserObj.newMsg = e.data.content || "在线";
                  this.selectedUserObj.unRead = this.selectedUserObj.unRead + 1;
                  this.selectedUserObj.overTime = 1;
                  this.selectedUserObj.msgList.push(newMsgObj);
                }
                // this.toMsgBottom(); 需要查看历史消息不打扰，故此时不滚动
              }
            }
          }
        }
        // 因为耗时操作，用户都还没有进入列表的情况进行再次调用
        if (readyIndex === -1) {
          const t = e.data.time.split(":").map(Number);
          const n = new Date();
          if (n.getMinutes() * 60 + n.getSeconds() - (t[1] * 60 + t[2]) < 120) {
            this.consoleLog("readyIndex === -1", e);
            await this.handleWsMsg(e);
          } else {
            this.consoleLog("readyIndex === -1", "失败");
          }
        } else {
          this.playBgMusic();
          const receipt = {
            type: "ack",
            to_id: e.from_id,
            mid: e.data.mid,
            uid: e.to_id,
          };
          this.websocket.send(JSON.stringify(receipt)); // 发送回执
          setTimeout(() => {
            resolve();
          }, 150);
        }
      });
    },
    async wxIccidListen(e) {
      console.log("wxIccidListen:", e);
      if (e.data && e.data.iccid) {
        this.selectedUserObj.carryIccid = e.data.iccid;
        let supplier = e.data.supplier;
        // supplier.uid = e.data.iccid;
        try {
          await this.$zkHttp.interact$index$listen({
            kf_id: this.serverId,
            uid: e.from_id,
            iccid: e.data,
            supplier,
          });
        } catch (e) {
          console.log("wxIccidListen err:", e);
        }
      }
    },
    async sendWxMsg(type, carryMsg) {
      // type:0富文本（字符串+表情）；1图片；2视频；3补卡链接；4实名公众号链接；5本小程序链接
      if (!this.selectedUserObj.userId) {
        this.$message({
          message: "无服务对象！",
          type: "waring",
        });
        return;
      }
      if (!carryMsg) {
        this.$message({
          message: "无消息内容！",
          type: "waring",
        });
        return;
      }
      let wxMsg = "";
      switch (type) {
        case 0:
          wxMsg = carryMsg;
          break;
        case 1:
          wxMsg = carryMsg;
          break;
        case 2:
          wxMsg = carryMsg;
          break;
        case 3:
        case 4:
        case 5:
          wxMsg = JSON.stringify(carryMsg);
          break;
        default:
          break;
      }
      let quote_id = 0;
      let quote_data = null;
      if (this.selectedUserObj.quoteItem) {
        quote_id = this.selectedUserObj.quoteItem.id || 0;
        quote_data = this.selectedUserObj.quoteItem;
        this.selectedUserObj.quoteItem = null;
      }
      const sendRes = await this.$zkHttp.interact$index$insert_log({
        from_id: this.serverId,
        from_name: this.serverName,
        from_avatar: this.serverAvatar,
        to_id: this.selectedUserObj.userId,
        content: wxMsg,
        msgtype: type || 0,
        quote_id,
      });
      // 未结束的服务对象可以消息入库和长链接发送；
      // 已结束的服务对象只能消息入库；不能长链接发送；
      if (this.showIndex === 0) {
        this.websocket.send(
          JSON.stringify({
            type: "chatMessage",
            data: {
              from_id: this.serverId,
              name: this.serverName,
              avatar: this.serverAvatar,
              to_id: this.selectedUserObj.userId,
              content: wxMsg,
              tag: "kf",
              mid: sendRes.id,
              msgtype: type || 0,
              quote_id,
            },
          })
        );
      }
      let wxMsgDom = "";
      switch (type) {
        case 0:
          wxMsgDom = wxMsg;
          this.editor.txt.clear();
          break;
        case 3:
          wxMsgDom = wxMsg; // `<p>🔗${JSON.parse(wxMsg).content || "补卡收货链接"}</p>`;
          break;
        case 4:
          wxMsgDom = wxMsg; // `<p>🔗${JSON.parse(wxMsg).content || "实名认证链接"}</p>`;
          break;
        case 5:
          wxMsgDom = wxMsg; // `<p>🔗${JSON.parse(wxMsg).content || "小程序链接"}</p>`;
          break;
        default:
          wxMsgDom = wxMsg;
          break;
      }
      const newMsgObj = {
        content: wxMsgDom,
        from_id: this.serverId,
        from_name: this.serverName,
        from_avatar: this.serverAvatar,
        to_id: this.selectedUserObj.userId,
        to_name: this.selectedUserObj.userName,
        id: sendRes.id || "",
        is_out: "",
        is_ready: "",
        msgtype: type || 0,
        quote_data,
        quote_id,
        status: "",
        time_line: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      };
      console.log("sendWxMsg:", newMsgObj);

      if (this.showIndex === 0) {
        for (let i = 0; i < this.userList.length; i++) {
          let userItem = this.userList[i];
          if (userItem.userId === this.selectedUserObj.userId) {
            if (this.selectedUserObj.replyNum === 0) {
              await this.$zkHttp.interact$service$accept_first({
                s_id: this.selectedUserObj.matchInfo.id,
                kf_id: this.$store.state.auth.loginInfo.uid,
                uid: this.selectedUserObj.userId,
              });
              this.selectedUserObj.replyNum = 1;
            }
            this.userList[i] = JSON.parse(JSON.stringify(this.selectedUserObj));
            this.userList[i].msgList.push(newMsgObj);
            this.userList[i].unRead = 0;
            if (this.userList[i].overTime) {
              this.userList[i].overTime = 0;
            }
            this.selectedUserObj = JSON.parse(JSON.stringify(this.userList[i]));
            this.toMsgBottom();
          }
        }
      } else {
        for (let i = 0; i < this.endList.length; i++) {
          let endItem = this.endList[i];
          if (endItem.userId === this.selectedUserObj.userId) {
            this.endList[i] = JSON.parse(JSON.stringify(this.selectedUserObj));
            this.endList[i].msgList.push(newMsgObj);
            this.endList[i].unRead = 0;
            if (this.endList[i].overTime) {
              this.endList[i].overTime = 0;
            }
            this.selectedUserObj = JSON.parse(JSON.stringify(this.endList[i]));
            this.toMsgBottom();
          }
        }
      }
    },
    async recallMsg(id) {
      const recallRes = await this.$zkHttp.chat$index$reset({
        id,
      });
      this.websocket.send(
        JSON.stringify({
          type: "reset",
          data: {
            uid: this.selectedUserObj.userId,
            mid: id,
          },
        })
      );
      for (let i = 0; i < this.selectedUserObj.msgList.length; i++) {
        const item = this.selectedUserObj.msgList[i];
        if (item.id == id) {
          this.selectedUserObj.msgList[i].status = 1;
          console.log("recallMsg:", this.selectedUserObj.msgList[i]);
        }
      }
    },
    reEditRecall(e) {
      this.editor.txt.html(e.content);
    },
    quoteMsg(e) {
      console.log("quoteMsg:", e);
      const reg = /<[^>]+>/g;
      if (!reg.test(e.content)) {
        e.content = `<span>${e.content}</span>`;
      }
      this.selectedUserObj.quoteItem = e;
    },
    cancelQuote() {
      this.selectedUserObj.quoteItem = null;
    },
    setUserAck(e) {
      for (let i = 0; i < this.userList.length; i++) {
        const item = this.userList[i];
        if (item.userId === e.from_id) {
          this.userList[i].newMsg = "";
          if (this.selectedUserObj.userId === e.from_id) {
            this.selectedUserObj.newMsg = "";
          }
        }
      }
    },
    async setUserClose(e) {
      for (let i = 0; i < this.userList.length; i++) {
        const item = this.userList[i];
        if (item.userId === e.uid) {
          if (this.selectedUserObj.newMsg !== "已离线") {
            await this.$zkHttp.interact$index$end_service({
              uid: this.selectedUserObj.userId,
              kf_id: this.serverId,
            });
          }
          this.userList[i].newMsg = "已离线";
          if (this.selectedUserObj.userId === e.uid) {
            this.selectedUserObj.newMsg = "已离线";
          }
        }
      }
    },
    async doDelClose(e) {
      this.userSet = this.userSet.filter((item) => item != e.uid);
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].userId === e.uid) {
          if (e.uid === this.selectedUserObj.userId) {
            if (this.userList[i + 1]) {
              this.selectedUserObj = JSON.parse(
                JSON.stringify(this.userList[i + 1])
              );
              this.initUserData();
            } else if (this.userList[i - 1]) {
              this.selectedUserObj = JSON.parse(
                JSON.stringify(this.userList[i - 1])
              );
              this.initUserData();
            } else {
              this.selectedUserObj = {};
            }
            this.iccidHeight =
              this.selectedUserObj.userInfo &&
              this.selectedUserObj.userInfo.iccid
                ? ["62px", "106px", "150px"][
                    JSON.parse(this.selectedUserObj.userInfo.iccid).length >= 3
                      ? "2"
                      : JSON.parse(this.selectedUserObj.userInfo.iccid).length
                  ]
                : "-20px";
          }
          this.userList.splice(i, 1);
          this.toMsgBottom();
          setTimeout(() => this.getChatEndList(), 150);
        }
      }
    },
    removeSelectedUser() {
      this.userSet = this.userSet.filter(
        (item) => item != this.selectedUserObj.userId
      );
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].userId === this.selectedUserObj.userId) {
          if (this.userList[i + 1]) {
            this.selectedUserObj = JSON.parse(
              JSON.stringify(this.userList[i + 1])
            );
            this.initUserData();
          } else if (this.userList[i - 1]) {
            this.selectedUserObj = JSON.parse(
              JSON.stringify(this.userList[i - 1])
            );
            this.initUserData();
          } else {
            this.selectedUserObj = {};
          }
          this.userList.splice(i, 1);
          this.iccidHeight =
            this.selectedUserObj.userInfo && this.selectedUserObj.userInfo.iccid
              ? ["62px", "106px", "150px"][
                  JSON.parse(this.selectedUserObj.userInfo.iccid).length >= 3
                    ? "2"
                    : JSON.parse(this.selectedUserObj.userInfo.iccid).length
                ]
              : "-20px";
          this.toMsgBottom();
          setTimeout(() => this.getChatEndList(), 150);
        }
      }
    },
    toMsgBottom() {
      setTimeout(() => {
        const newMsgDiv = document.getElementById(`msg_scroll`);
        if (newMsgDiv) {
          newMsgDiv.scrollTop = newMsgDiv.scrollHeight;
        }
      }, 0);
    },
    toMsgItem(e) {
      setTimeout(() => {
        // 获取要滚动到的目标元素
        const targetElement = document.getElementById(`msg_${e.id}`);
        // 调用 scrollIntoView() 方法进行滚动
        if (targetElement) targetElement.scrollIntoView();
      }, 0);
    },
    onEmojyClick(i) {
      console.log("onEmojyClick:", i);
      this.emojisVisible = false;
      const icon_url = `https://mini.wokahui.com/static/service/js/layui/images/face/${i}.gif`;
      this.editor.cmd.do("insertHTML", `<img src="${icon_url}" alt="""/>`);
    },
    // 获取快速回复列表
    async getPhrases() {
      const pRes = await this.$zkHttp.interact$index$get_speedy_word({
        kf_id: this.serverId,
      });
      this.phrases = pRes;
    },
    // 点击快速回复并发送
    phraseClick(p) {
      if (p.content) {
        this.sendWxMsg(p.msgtype, p.msgtype >= 3 ? p : p.content);
        this.phrasesVisible = false;
      }
    },
    // 添加快速回复
    addPhrase() {
      this.phraseInfo = {};
      this.phraseEditDrawer = true;
    },
    // 编辑快速回复
    phraseEdit(p) {
      this.phraseInfo = p;
      this.phraseEditDrawer = true;
    },
    // 关闭编辑快速回复
    phraseEditDrawerClose() {
      this.phraseEditDrawer = false;
      setTimeout(() => {
        this.phrasesVisible = true;
      }, 0);
      this.getPhrases();
    },
    // 关闭前检查,此时不关闭
    phrasesHide() {
      if (this.phraseEditDrawer) {
        this.phrasesVisible = true;
      }
    },
    // 删除快速回复
    phraseDelete(p) {
      if (p.id) {
        this.$confirm("您确定删除该条快速回复么")
          .then(async () => {
            this.phrasesVisible = true;
            const delRes = await this.$zkHttp.interact$service$del_quick_words({
              kf_id: this.serverId,
              id: p.id,
            });
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getPhrases();
          })
          .catch(() => {
            // this.$message({
            //   message: "已取消",
            //   type: "info",
            // });
          })
          .finally(() => {
            this.phrasesVisible = true;
          });
      }
    },
    async chooseImg() {
      const file = await new Promise((res, rej) => {
        let options = {
          accept: "image/*",
          multiple: false,
        };

        const el = document.createElement("input");
        el.type = "file";
        el.accept = options.accept;
        el.multiple = options.multiple;
        el.addEventListener("change", (_) => {
          if (options.multiple) {
            return res(el.files);
          } else {
            return res(el.files[0]);
          }
        });
        el.click();
      });
      await this.uploadImg(file);
    },
    async uploadImg(file) {
      let imgData = new FormData();
      imgData.append("file", file);
      imgData.append("file_type", "image");
      //调用上传图片接口，上传图片
      const upRes = await this.$http
        .post(
          "https://mini.wokahui.com/interact/upload/up_file?token=" +
            this.$store.state.auth.loginInfo.token,
          imgData
        )
        .then((res) => {
          return res.body.data;
        });
      console.log("img upRes", upRes.full_url);

      // 插入消息框后发送
      const id = Base64.encode(upRes.full_url);
      this.editor.cmd.do(
        "insertHTML",
        `<img src="${upRes.full_url}" alt="" id="${id}" data-tagname="showBigImg"   />`
      );
      const self = this;
      const node = document.getElementById(id);
      // 给节点添加点击事件
      node.addEventListener("click", function (event) {
        // 在这里处理点击事件
        self.showBigImg(upRes.full_url);
      });

      // 直接发送
      // this.sendWxMsg(1, `<img src="${upRes.full_url}" alt=""/>`);
    },
    showBigImg(url) {
      console.log("showBigImg", url);
      try {
        // 创建一个包含图片URL的数组
        const imageUrlList = [url];
        // 使用el-image的preview-src-list属性来预览图片
        this.showBigImagePreviewSrcList = imageUrlList;
        // 显示预览
        this.$refs.showBigImageRef.showViewer = true;
      } catch (e) {
        console.log("showBigImg err", e);
      }
    },
    showBigImgTag(e) {
      console.log("showBigImgTag", e);
      const tagName = e.target.dataset.tagname;
      if (tagName === "showBigImg") {
        const url = Base64.decode(e.target.id);
        this.showBigImg(url);
      } else {
        console.log("未匹配");
      }
    },
    async chooseVideo() {
      const file = await new Promise((res, rej) => {
        let options = {
          accept: "video/*",
          multiple: false,
        };

        const el = document.createElement("input");
        el.type = "file";
        el.accept = options.accept;
        el.multiple = options.multiple;
        el.addEventListener("change", (_) => {
          if (options.multiple) {
            return res(el.files);
          } else {
            return res(el.files[0]);
          }
        });
        el.click();
      });
      await this.uploadVideo(file);
    },
    async uploadVideo(file) {
      let imgData = new FormData();
      imgData.append("file", file);
      imgData.append("file_type", "video");
      //调用上传图片接口，上传图片
      const upRes = await this.$http
        .post(
          "https://mini.wokahui.com/interact/upload/up_file?token=" +
            this.$store.state.auth.loginInfo.token,
          imgData
        )
        .then((res) => {
          return res.body.data;
        });
      console.log("video upRes", upRes.full_url);
      this.sendWxMsg(
        2,
        `<video src="${upRes.full_url}" alt=""  preload="auto" controls/>`
      );
    },
    async changeOver() {
      console.log("changeOver: start");
      this.websocket.send(
        JSON.stringify({
          type: "changeManage",
          user: {
            uid: this.selectedUserObj.userId,
            name: this.selectedUserObj.userName,
            avatar: this.selectedUserObj.userAvatar,
          },
          group: this.$store.state.auth.loginInfo.group,
          change: this.serverId,
        })
      );
    },
    async changeOverSuccess(e) {
      console.log("changeOverSuccess:", e);
      const changeRes = await this.$zkHttp.interact$index$change_service({
        uid: e.uid, // 用户id
        kf_id: e.relink, // 目标客服id
      });
      if (changeRes) {
        this.removeSelectedUser();
        this.$message({
          message: "转接成功！",
          type: "success",
        });
      }
    },
    async changeOverWh() {
      console.log("changeOverWh: start", {
        type: "changGroup",
        uid: this.selectedUserObj.userId,
        group: this.$store.state.auth.loginInfo.group,
        newGroup: this.$store.state.auth.loginInfo.group != 13 ? 13 : 1,
        change: this.serverId,
        user: {
          uid: this.selectedUserObj.userId,
          name: this.selectedUserObj.userName,
          avatar: this.selectedUserObj.userAvatar,
        },
      });
      this.websocket.send(
        JSON.stringify({
          type: "changGroup",
          uid: this.selectedUserObj.userId,
          group: this.$store.state.auth.loginInfo.group,
          newGroup: this.$store.state.auth.loginInfo.group != 13 ? 13 : 1,
          change: this.serverId,
          user: {
            uid: this.selectedUserObj.userId,
            name: this.selectedUserObj.userName,
            avatar: this.selectedUserObj.userAvatar,
          },
        })
      );
    },
    async changeOverWhSuccess(e) {
      console.log("changeOverWhSuccess:", e);
      const changeRes = await this.$zkHttp.interact$index$change_group({
        uid: e.uid,
        group: e.relink,
      });
      if (changeRes) {
        this.removeSelectedUser();
        this.$message({
          message: "转接武汉成功！",
          type: "success",
        });
      }
    },
    async appealIncivilization() {
      console.log("appealIncivilization:");
      await this.$zkHttp.interact$service$block({
        uid: this.serverId,
        reason: "言语辱骂",
      });
      this.$message({
        message: "申诉成功！",
        type: "success",
      });
    },
    async serveCloseDrawerOpen() {
      const qRes = await this.$zkHttp.interact$index$summary({
        kf_id: this.serverId,
        uid: this.selectedUserObj.userId,
      });
      this.summary = qRes;
      setTimeout(() => (this.serveCloseDrawer = true), 150);
      console.log("this.summary", this.summary);
    },
    serveCloseDrawerClose(e) {
      this.serveCloseDrawer = false;
      if (e) {
        this.websocket.send(
          JSON.stringify({
            type: "mainCloseUser",
            uid: this.selectedUserObj.userId,
            kf_id: this.$store.state.auth.loginInfo.uid,
            group: this.$store.state.auth.loginInfo.group,
            users: this.userList.length,
          })
        );
        this.removeSelectedUser();
      }
    },
    copySomething() {
      console.log("copySomething");
      let clipboard = new Clipboard("#copySomething");
      const self = this;
      clipboard.on("success", (e) => {
        self.$message({
          message: "已复制！直接去粘贴发送吧！",
          type: "success",
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        self.$message({
          message: "该浏览器不支持！",
          type: "fail",
        });
        clipboard.destroy();
      });
    },
  },
};
