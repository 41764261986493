import { doRequest } from "./http";

let baseApiHttp = {
  // 客服登录
  interact$login$index: ({ job_num, password, loginTmpMark }) => {
    return doRequest("POST", {
      url: "interact/login/index",
      params: {},
      body: {
        job_num,
        password,
        loginTmpMark,
      },
    });
  },
  // 客服退出登录
  interact$login$login_out: ({ kf_id }) => {
    return doRequest("POST", {
      url: "interact/login/login_out",
      params: {},
      body: {
        kf_id,
      },
    });
  },
  // 获取用户信息
  interact$index$get_user_info: ({ uid }) => {
    return doRequest("GET", {
      url: "interact/index/get_user_info",
      params: { uid },
    });
  },
  // 获取个人中心
  interact$index$personal: ({ kf_id, group }) => {
    return doRequest("GET", {
      url: "interact/index/personal",
      params: { kf_id, group },
    });
  },
  // 修改密码
  service$login$password: ({ pass, kf_id }) => {
    return doRequest("POST", {
      url: "service/login/password",
      body: { pass, kf_id },
    });
  },
  // 获取快捷用语
  interact$index$get_speedy_word: ({ kf_id }) => {
    return doRequest("POST", {
      url: "interact/index/get_speedy_word",
      body: { kf_id },
    });
  },
  // 获取服务总结问题列表
  interact$index$summary: ({ kf_id, uid }) => {
    return doRequest("GET", {
      url: "interact/index/summary",
      params: { kf_id, uid },
    });
  },
  // 文件上传
  interact$upload$up_file: ({ file_type, file }) => {
    return doRequest("POST", {
      url: "interact/upload/up_file",
      body: { file_type, file },
    });
  },
  // 获取补卡类别和工单类别
  interact$index$sheet_type_list: ({ kf_id }) => {
    return doRequest("POST", {
      url: "interact/index/sheet_type_list",
      body: { kf_id },
    });
  },
  // 获取补卡原因
  interact$service$get_reason: ({ kf_id }) => {
    return doRequest("POST", {
      url: "interact/service/get_reason",
      body: { kf_id },
    });
  },
  // 已排查项
  interact$service$getChecksUid: ({ uid, iccid }) => {
    return doRequest("GET", {
      url: "interact/service/getChecksUid",
      params: { uid, iccid },
    });
  },
  // 记录排查项
  interact$service$addcheck: ({ kf_id, uid, title, action, iccid }) => {
    return doRequest("POST", {
      url: "interact/service/addcheck",
      body: {
        kf_id,
        uid,
        title,
        action,
        iccid,
      },
    });
  },
  // 获取历史记录
  interact$index$get_service_log: ({ uid }) => {
    return doRequest("GET", {
      url: "interact/index/get_service_log",
      params: { uid },
    });
  },
  // 获取投诉列表
  interact$index$get_compliant: ({ uid }) => {
    return doRequest("GET", {
      url: "interact/index/get_compliant",
      params: { uid },
    });
  },
  // 获取用户工单列表
  interact$index$get_sheets: ({ uid }) => {
    return doRequest("GET", {
      url: "interact/index/get_sheets",
      params: { uid },
    });
  },
  // 获取工单详情
  interact$index$get_sheets_detail: ({ id }) => {
    return doRequest("GET", {
      url: "interact/index/get_sheets_detail",
      params: { id },
    });
  },
  // 获取用户订单列表
  interact$index$get_saleds: ({ uid }) => {
    return doRequest("GET", {
      url: "interact/index/get_saleds",
      params: { uid },
    });
  },
  // 获取用户订单详情
  interact$index$get_saleds_detail: ({ id }) => {
    return doRequest("GET", {
      url: "interact/index/get_saleds_detail",
      params: { id },
    });
  },
  // 获取用户退款列表
  interact$index$get_drawback: ({ from_node }) => {
    return doRequest("GET", {
      url: "interact/index/get_drawback",
      params: { from_node },
    });
  },
  // 获取退款详情
  interact$index$get_drawback_info: ({ id }) => {
    return doRequest("GET", {
      url: "interact/index/get_drawback_info",
      params: { id },
    });
  },
  // 提交工单
  interact$service$addSheet: ({
    kf_id,
    uid,
    user_name,
    title,
    type,
    phone,
    level,
    content,
  }) => {
    return doRequest("POST", {
      url: "interact/service/addSheet",
      body: { kf_id, uid, user_name, title, type, phone, level, content },
    });
  },
  // 再次提交工单
  interact$service$addSheeted: ({
    kf_id,
    uid,
    user_name,
    title,
    type,
    phone,
    level,
    content,
  }) => {
    return doRequest("POST", {
      url: "interact/service/addSheeted",
      body: { kf_id, uid, user_name, title, type, phone, level, content },
    });
  },
  // 用户补卡
  interact$service$addCard: ({
    kf_id,
    uid,
    user_name,
    store,
    phone_num,
    card_type,
    content,
    num,
    money,
    des,
  }) => {
    return doRequest("POST", {
      url: "interact/service/addCard",
      body: {
        kf_id,
        uid,
        user_name,
        store,
        phone_num,
        card_type,
        content,
        num,
        money,
        des,
      },
    });
  },
  // 获取退款类别&来源
  interact$service$get_refund_type: () => {
    return doRequest("GET", {
      url: "interact/service/get_refund_type",
    });
  },
  // 根据订单获取号卡数据
  interact$service$get_card_refund: ({ order_num }) => {
    return doRequest("GET", {
      url: "interact/service/get_card_refund",
      params: {
        order_num,
      },
    });
  },
  // 客服提交退款订单
  interact$service$add_refund: (data) => {
    return doRequest("POST", {
      url: "interact/service/add_refund",
      body: {
        ...data,
      },
    });
  },
  // 客服添加&编辑快捷回复
  interact$service$add_quick_words: ({
    kf_id,
    kf_name,
    title,
    content,
    id,
  }) => {
    return doRequest("POST", {
      url: "interact/service/add_quick_words",
      body: {
        kf_id,
        kf_name,
        title,
        content,
        id,
      },
    });
  },
  // 客服删除快捷回复
  interact$service$del_quick_words: ({ kf_id, id }) => {
    return doRequest("POST", {
      url: "interact/service/del_quick_words",
      body: {
        kf_id,
        id,
      },
    });
  },
};

export default baseApiHttp;
