<template>
  <div class="workDetail">
    <h1>
      <span>{{ workDetail.title }} [{{ workDetail.type }}]</span>
    </h1>
    <p>
      <span>上传人：</span><span>{{ workDetail.add_user }}</span>
    </p>
    <p>
      <span>联系电话：</span><span>{{ workDetail.phone }}</span>
    </p>
    <p>
      <span>上传时间：</span><span>{{ workDetail.create_time }}</span>
    </p>
    <p>
      <span>超时时间：</span
      ><span style="color: #e4340c">{{ workDetail.over_time }}</span>
    </p>
    <div class="content" v-html="workDetail.content"></div>
    <h1>
      <i class="el-icon-s-platform" title="" style="color: #e4340c"></i>
      <span>处理意见：</span>
    </h1>
    <div class="content">
      <el-timeline v-if="workDetail.logs && workDetail.logs.length > 0">
        <el-timeline-item
          v-for="(item, index) in workDetail.logs"
          :key="item.id"
          :timestamp="
            moment(item.add_time * 1000).format('YYYY-MM-DD HH:mm:ss')
          "
          v-if="item.type === 1"
        >
          [{{ item.add_user }}]: <span v-html="item.content"></span>
        </el-timeline-item>
      </el-timeline>
    </div>
    <h1>
      <i class="el-icon-s-platform" title="" style="color: #e4340c"></i>
      <span>回访记录：</span>
    </h1>
    <div class="content">
      <el-timeline v-if="workDetail.logs && workDetail.logs.length > 0">
        <el-timeline-item
          v-for="(item, index) in workDetail.logs"
          :key="item.id"
          :timestamp="
            moment(item.add_time * 1000).format('YYYY-MM-DD HH:mm:ss')
          "
          v-if="item.type === 2"
        >
          [{{ item.add_user }}]: {{ item.content }}
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "workDetailLayer", // 工单详情
  props: {
    workDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    formattedTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss"); // 这里的'time'为传递到组件的时间数据
    },
  },
  mounted() {
    // this.tableData = [];
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.workDetail {
  padding: 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > h1 {
    margin: 8px 0;
    width: 100%;
    font-size: 15px;
    line-height: 25px;
    > i {
      margin-right: 5px;
    }
  }
  > p {
    margin: 5px 0;
    width: 50%;
    display: flex;
    flex-direction: row;
    > span:first-child {
      width: 65px;
      text-align: right;
      display: inline-block;
      font-size: 13px;
      line-height: 25px;
      opacity: 0.5;
      margin-right: 5px;
    }
    > span:last-child {
      width: calc(100% - 70px);
      display: inline-block;
      font-size: 15px;
      line-height: 25px;
      word-break: break-all;
    }
  }
  .content {
    padding: 10px;
    width: calc(100% - 20px);
    overflow: auto;
    border: 1px solid #e7e9f2;
    border-radius: 2px;
    .el-timeline {
      padding-inline-start: 10px;
      margin-block-start: 20px;
      margin-block-end: 0px;
    }
    ::v-deep p {
      width: 100%;
      > img {
        max-width: 80% !important;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .workDetail > p {
    width: 100% !important;
  }
}
</style>
<script setup></script>
