<template>
  <div class="indexView">
    <el-container>
      <!-- 头部导航 -->
      <el-header>
        <el-aside width="165px">
          <el-image
            style="width: 45px; height: 39px"
            fit="contain"
            :src="require('@/assets/home/logo.png')"
            @dblclick="setFullScreen"
          ></el-image>
          <h1 id="simulated">云客数据</h1>
        </el-aside>
        <el-main>
          <el-menu mode="horizontal">
            <el-menu-item index="0">排队中({{ waitNum }}人)</el-menu-item>
            <el-menu-item index="1" @click="refundOpen()">
              退款登记
            </el-menu-item>
            <el-menu-item index="2">
              <a class="a-link" :href="constObj.link_htgl" target="_blank">
                后台管理
              </a>
              <!--              <a-->
              <!--                  class="a-link"-->
              <!--                  :href="`${constObj.link_htgl2}?kf_id=${serverId}&ser_token=${encryptedStr}`"-->
              <!--                  target="_blank"-->
              <!--              >-->
              <!--                后台管理-->
              <!--              </a>-->
            </el-menu-item>
            <el-submenu index="3">
              <template slot="title">常用后台</template>
              <el-menu-item index="3-0">
                <a class="a-link" :href="constObj.link_zshk" target="_blank">
                  沃卡惠专属号卡管理系统
                </a>
              </el-menu-item>
              <el-menu-item index="3-1">
                <a :href="constObj.link_hk" target="_blank">
                  沃卡惠号卡管理系统
                </a>
              </el-menu-item>
              <el-menu-item index="3-2">
                <a :href="constObj.link_lt" target="_blank"> 联通沃卡惠 </a>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">主题</template>
              <el-menu-item
                v-for="theme in themes"
                :key="theme.key"
                :index="theme.key"
                :disabled="theme.key === selectedTheme"
                @click="onSwitchTheme(theme.key)"
              >
                {{ theme.label }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
          <div class="server" @click="myCenterDrawerOpen()">
            <el-avatar
              :size="40"
              :src="require('@/assets/home/new_kf.png')"
            ></el-avatar>
            <h2>个人中心</h2>
          </div>
          <el-switch
            v-model="online"
            :active-text="online ? '在线' : '离线'"
            active-color="green"
            inactive-color="gray"
            @change="setServeStatus"
          >
          </el-switch>
        </el-main>
      </el-header>
      <!-- 内容 -->
      <el-container>
        <!-- 内容左侧聊天列表 -->
        <el-aside width="220px">
          <el-header>
            <el-row>
              <el-col
                :class="showIndex === 0 ? 'active' : ''"
                :span="12"
                @click.native="onSwitchChatList(0)"
              >
                <h1>当前会话</h1>
                <h2>({{ userList.length || 0 }}人)</h2>
              </el-col>
              <el-col
                :class="showIndex === 1 ? 'active' : ''"
                :span="12"
                @click.native="onSwitchChatList(1)"
              >
                <h1>历史会话</h1>
                <h2>({{ endList.length || 0 }}人)</h2>
              </el-col>
            </el-row>
          </el-header>
          <el-container>
            <div
              v-if="showIndex === 0"
              v-for="(user, index) in userList"
              :key="`${showIndex}_${selectedUserObj.userId}_${user.userId}`"
              :class="
                selectedUserObj.userId === user.userId
                  ? 'userItem active'
                  : 'userItem'
              "
              @click="onSwitchUser(user)"
            >
              <!-- <div class="sort">{{ index + 1 }}</div>-->
              <div
                :class="user.newMsg === '已离线' ? 'avatar leave' : 'avatar'"
              >
                <img
                  :src="user.userAvatar"
                  :class="user.overTime >= constObj.overTime ? 'imgHint' : ''"
                />
                <span v-if="user.unRead > 0">{{ user.unRead }}</span>
                <span
                  v-else-if="user.replyNum === 0"
                  style="transform: scale(0.5)"
                ></span>
              </div>
              <div class="info">
                <h1 :title="user.userName">
                  {{ user.userName || "用户昵称" }}
                </h1>
                <span v-html="user.newMsg"></span>
              </div>
            </div>
            <div
              v-if="showIndex === 1"
              v-for="(user, index) in endList"
              :key="`${showIndex}_${selectedUserObj.userId}_${user.userId}`"
              :class="
                selectedUserObj.userId === user.userId
                  ? 'userItem active'
                  : 'userItem'
              "
              @click="onSwitchEnd(user)"
            >
              <!-- <div class="sort">{{ index + 1 }}</div>-->
              <div class="avatar" style="filter: grayscale(0.7)">
                <img :src="user.userAvatar" />
              </div>
              <div class="info">
                <h1 :title="user.userName">
                  {{ user.userName || "用户昵称" }}
                </h1>
                <span></span>
              </div>
            </div>
            <p
              style="
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 9999;
                padding: 4px 8px;
                color: red;
                text-shadow: white;
                font-size: 12px;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.7);
              "
              id="copySomething"
              data-clipboard-action="copy"
              :data-clipboard-text="JSON.stringify(consoleLogs)"
              @click="copySomething"
            >
              调试版{{ $store.state.host.env }}.v{{
                $store.state.version
              }}【点击提取日志】
            </p>
          </el-container>
        </el-aside>
        <!-- 内容右侧 -->
        <el-main>
          <el-row>
            <!-- 右侧消息区 -->
            <el-col :span="12" class="chatBox">
              <el-header>
                <div v-if="selectedUserObj.userId">
                  <h1>{{ selectedUserObj.userName || "用户昵称" }}</h1>
                  <h2 v-if="showIndex === 0">
                    服务时长：{{ parseInt(selectedUserObj.serveTime / 60) }}分{{
                      selectedUserObj.serveTime % 60
                    }}秒
                  </h2>
                  <h2 v-else>服务已结束</h2>
                </div>
              </el-header>
              <el-main class="midChat" id="msg_scroll">
                <div class="chatList" :key="selectedUserObj.userId">
                  <div
                    class="timeLine"
                    style="cursor: pointer"
                    @click="getMoreWsMsg"
                  >
                    查看历史聊天记录
                  </div>
                  <div
                    v-for="(msg, index) in selectedUserObj.msgList"
                    :key="msg.id"
                  >
                    <div
                      v-if="
                        msg.status === 0 ||
                        msg.status === '' ||
                        msg.status === null
                      "
                    >
                      <div
                        v-if="msg.to_id === selectedUserObj.userId"
                        class="serverItem"
                      >
                        <div class="msgInfo">
                          <h1>
                            <span style="font-size: 12px; opacity: 0.3">
                              {{ TimeLineDom(msg.time_line) }}
                            </span>
                            <span>{{
                              msg.from_name === serverName
                                ? "我"
                                : msg.from_name
                            }}</span>
                          </h1>
                          <div class="msg" :id="`msg_${msg.id}`">
                            <!-- {{ msg.content }}-->
                            <div
                              v-if="msg.msgtype === 0"
                              v-html="msg.content"
                              @click="showBigImgTag"
                            />
                            <el-image
                              v-else-if="msg.msgtype === 1"
                              style="height: 150px"
                              :src="msg.content.match(linkRegExp)[0]"
                              :preview-src-list="[
                                msg.content.match(linkRegExp)[0],
                              ]"
                            />
                            <!--                            <video-->
                            <!--                              v-else-if="msg.msgtype === 2"-->
                            <!--                              style="height: 150px"-->
                            <!--                              :src="msg.content"-->
                            <!--                              controls-->
                            <!--                            ></video>-->
                            <div
                              v-else-if="
                                msg.msgtype === 3 ||
                                msg.msgtype === 4 ||
                                msg.msgtype === 5
                              "
                              v-html="doMsgDom(msg.content)"
                            />
                            <div v-else v-html="msg.content" />
                            <div
                              class="quoteMsg"
                              v-if="msg.quote_data"
                              @click="toMsgItem(msg.quote_data)"
                            >
                              【{{
                                msg.quote_data.to_id === selectedUserObj.userId
                                  ? `${
                                      msg.from_name === serverName
                                        ? "我"
                                        : msg.from_name
                                    }`
                                  : `${msg.quote_data.from_name}`
                              }}】：
                              <span
                                v-if="msg.quote_data.msgtype === 0"
                                v-html="msg.quote_data.content"
                              />
                              <span v-else>
                                {{
                                  constObj.msgTypeName[msg.quote_data.msgtype]
                                }}
                              </span>
                            </div>
                            <div class="msgAction">
                              <div @click="quoteMsg(msg)">引用</div>
                              <div slot="reference" @click="recallMsg(msg.id)">
                                撤回
                              </div>
                            </div>
                          </div>
                        </div>
                        <img :src="msg.from_avatar" />
                      </div>
                      <div v-else class="userItem">
                        <img :src="msg.from_avatar" />
                        <div class="msgInfo">
                          <h1>
                            <span>{{ msg.from_name || "昵称" }}</span>
                            <span style="font-size: 12px; opacity: 0.3">
                              {{ TimeLineDom(msg.time_line) }}
                            </span>
                          </h1>
                          <div class="msg" :id="`msg_${msg.id}`">
                            <!-- {{ msg.content }}-->
                            <div
                              v-if="msg.msgtype === 0"
                              v-html="msg.content"
                            />
                            <el-image
                              v-else-if="msg.msgtype === 1"
                              style="height: 150px"
                              :src="msg.content.match(linkRegExp)[0]"
                              :preview-src-list="[
                                msg.content.match(linkRegExp)[0],
                              ]"
                            />
                            <!--                            <video-->
                            <!--                              v-else-if="msg.msgtype === 2"-->
                            <!--                              style="height: 150px"-->
                            <!--                              :src="msg.content"-->
                            <!--                              controls-->
                            <!--                            ></video>-->
                            <div
                              v-else-if="
                                msg.msgtype === 3 ||
                                msg.msgtype === 4 ||
                                msg.msgtype === 5
                              "
                              v-html="doMsgDom(msg.content)"
                            />
                            <div v-else v-html="msg.content" />
                            <div
                              class="quoteMsg"
                              v-if="msg.quote_data"
                              @click="toMsgItem(msg.quote_data)"
                            >
                              【{{
                                msg.quote_data.to_id === selectedUserObj.userId
                                  ? `${
                                      msg.from_name === serverName
                                        ? "我"
                                        : msg.from_name
                                    }`
                                  : `${msg.quote_data.from_name}`
                              }}】：
                              <span
                                v-if="msg.quote_data.msgtype === 0"
                                v-html="msg.quote_data.content"
                              />
                              <span v-else>
                                {{
                                  constObj.msgTypeName[msg.quote_data.msgtype]
                                }}
                              </span>
                            </div>
                            <div class="msgAction">
                              <div @click="quoteMsg(msg)">引用</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="timeLine"
                        v-if="
                          DiffLastMsgTimeLine(selectedUserObj.msgList, index)
                        "
                      >
                        以上为历史消息
                      </div>
                    </div>
                    <div v-if="msg.status === 1 && DiffNow(msg.time_line)">
                      <div class="recallLine">
                        你撤回了一条消息
                        <span @click="reEditRecall(msg)">重新编辑</span>
                      </div>
                    </div>
                  </div>
                </div>
                <elImage
                  ref="showBigImageRef"
                  style="display: none"
                  :preview-src-list="showBigImagePreviewSrcList"
                >
                </elImage>
              </el-main>
              <el-footer :class="!selectedUserObj.userId ? 'disable' : ''">
                <div class="chatTools" @dblclick="toMsgBottom">
                  <div class="leftTools" @dblclick.stop="">
                    <el-popover
                      placement="top-start"
                      :width="popoverWidth"
                      v-model="emojisVisible"
                    >
                      <div class="popover">
                        <div class="emojis">
                          <div
                            class="emojy"
                            v-for="i in 95"
                            :key="`emojy-${i}`"
                            @click="onEmojyClick(i)"
                          >
                            <img
                              :src="require('@/assets/emojis/' + i + '.gif')"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="tool" slot="reference">
                        <i class="el-icon-eleme" title="表情"></i>
                      </div>
                    </el-popover>
                    <div class="tool" @click="chooseImg">
                      <i class="el-icon-picture-outline" title="图片"></i>
                    </div>
                    <div class="tool" @click="chooseVideo">
                      <i class="el-icon-video-camera" title="视频"></i>
                    </div>
                    <el-popover
                      placement="top-start"
                      :width="popoverWidth"
                      offset="120"
                      v-model="phrasesVisible"
                      @hide="phrasesHide"
                    >
                      <div class="popover">
                        <div class="phrases">
                          <div
                            class="msg"
                            v-for="p in phrases"
                            :key="'p_' + p.id"
                          >
                            <p>
                              {{ p.title }}
                              <i
                                v-if="p.kf_id !== 0"
                                class="el-icon-edit-outline"
                                title="编辑"
                                @click="phraseEdit(p)"
                              ></i>
                              <i
                                v-if="p.kf_id !== 0"
                                class="el-icon-delete"
                                title="删除"
                                @click="phraseDelete(p)"
                              ></i>
                            </p>
                            <!-- 0字符串,1:图片,2视频,3补卡连接,4实名,5本小程序的链接-->
                            <p
                              v-if="
                                p.msgtype === 3 ||
                                p.msgtype === 4 ||
                                p.msgtype === 5
                              "
                              v-html="`🔗${p.content}`"
                              @click="phraseClick(p)"
                            />
                            <p
                              v-else
                              v-html="p.content"
                              @click="phraseClick(p)"
                            />
                          </div>
                          <div class="add" @click="addPhrase">
                            <i
                              class="el-icon-circle-plus"
                              title="添加快速回复"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div class="tool" slot="reference">
                        <i
                          class="el-icon-document-checked"
                          title="快速回复"
                        ></i>
                      </div>
                    </el-popover>
                  </div>
                  <div class="rightTools" @dblclick.stop="">
                    <div class="tool">
                      <el-popconfirm
                        title="您确认转接给转接武汉客服？"
                        @confirm="changeOverWh()"
                      >
                        <i
                          slot="reference"
                          class="el-icon-refresh wh"
                          title="转接武汉客服"
                        ></i>
                      </el-popconfirm>
                    </div>
                    <div class="tool">
                      <el-popconfirm
                        title="您确认转接给其它客服？"
                        @confirm="changeOver()"
                      >
                        <i
                          slot="reference"
                          class="el-icon-refresh-left es"
                          title="转交其他客服"
                        ></i>
                      </el-popconfirm>
                    </div>
                    <div class="tool">
                      <el-popconfirm
                        title="您确认提交不文明申诉？"
                        @confirm="appealIncivilization()"
                      >
                        <i
                          slot="reference"
                          class="el-icon-warning-outline"
                          title="不文明申诉"
                        ></i>
                      </el-popconfirm>
                    </div>
                    <div class="tool" @click="serveCloseDrawerOpen()">
                      <i class="el-icon-switch-button" title="结束服务"></i>
                    </div>
                  </div>
                </div>
                <div class="chatEditor">
                  <div
                    class="quoteMsgEditor"
                    v-if="selectedUserObj.quoteItem"
                    @click="toMsgItem(selectedUserObj.quoteItem)"
                  >
                    <span>
                      【{{
                        selectedUserObj.quoteItem.from_id === serverId
                          ? `${
                              selectedUserObj.quoteItem.from_name === serverName
                                ? "我"
                                : selectedUserObj.quoteItem.from_name
                            }`
                          : `${selectedUserObj.quoteItem.from_name}`
                      }}】：
                    </span>
                    <span
                      v-if="selectedUserObj.quoteItem.msgtype === 0"
                      v-html="selectedUserObj.quoteItem.content"
                    />
                    <span v-else>
                      {{
                        constObj.msgTypeName[selectedUserObj.quoteItem.msgtype]
                      }}
                    </span>
                    <i
                      class="el-icon-circle-close"
                      title="取消"
                      @click="cancelQuote"
                    ></i>
                  </div>
                  <div id="wangEditor">
                    <div ref="myEditor"></div>
                  </div>
                </div>
              </el-footer>
            </el-col>
            <!-- 右侧操作区 -->
            <el-col :span="12" class="operateBox">
              <el-header>
                <div class="userCard">
                  <div
                    v-if="
                      selectedUserObj.userInfo && selectedUserObj.userInfo.level
                    "
                    class="userLabel"
                    :style="{
                      background: [
                        'linear-gradient(to left,#ffa800,#facc7f)',
                        'linear-gradient(to left,#fe8b30,#ffa301)',
                        'linear-gradient(to left,#ff7400,#fc9b57)',
                        'linear-gradient(to left,#fb6d0e,#f7883e)',
                        'linear-gradient(to left,#f1581c,#f8893e)',
                        'linear-gradient(to left,#eb5636,#ee734a)',
                      ][selectedUserObj.userInfo.level],
                    }"
                  >
                    {{
                      `${
                        ["", "一", "二", "三", "四", "五"][
                          selectedUserObj.userInfo.level
                        ]
                      }级用户`
                    }}
                  </div>
                  <div class="userInfo">
                    <el-image :src="selectedUserObj.userAvatar" fit="contain">
                      <div slot="error">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div class="right">
                      <div class="name">
                        <div>
                          {{ selectedUserObj.userName || "用户昵称" }}
                        </div>
                        <div>
                          {{
                            (selectedUserObj.userInfo &&
                              selectedUserObj.userInfo.from_name) ||
                            "(●'◡'●)"
                          }}
                        </div>
                      </div>
                      <div class="row">
                        <div>入网时间：</div>
                        <div>
                          {{
                            (selectedUserObj.userInfo &&
                              selectedUserObj.userInfo.create_time) ||
                            "--"
                          }}
                        </div>
                      </div>
                      <div class="row">
                        <div>IP地址：</div>
                        <div>
                          {{
                            (selectedUserObj.userInfo &&
                              selectedUserObj.userInfo.login_ip) ||
                            "--"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="checkInfo">
                    <div class="title">已排查:</div>
                    <div class="checkOptions">
                      <div
                        v-for="item in checkOptions"
                        :key="item.title"
                        :class="item.num > 0 ? 'item active' : 'item'"
                        @click="setUserChecked(item)"
                      >
                        {{ item.title }}
                        <i
                          class="el-icon-check"
                          title="已排查"
                          v-if="item.num > 0"
                        ></i>
                        <span v-if="item.num > 0">+{{ item.num }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="iccidSearch">
                    <el-input
                      class="input-with-select"
                      placeholder="请输入ICCID"
                      v-model="iccid"
                    >
                      <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="onSearchIccid()"
                      ></el-button>
                    </el-input>
                  </div>
                  <div
                    v-if="
                      selectedUserObj.userInfo && selectedUserObj.userInfo.iccid
                    "
                    class="iccidBox"
                  >
                    <el-table
                      :data="JSON.parse(selectedUserObj.userInfo.iccid)"
                      stripe
                      fit
                      border
                      :show-header="false"
                      :style="{
                        width: '100%',
                        height: iccidHeight,
                        maxHeight: 'calc(100vh - 295px)',
                      }"
                      :row-class-name="tableRowClassName"
                      @row-dblclick="onSearchIccid"
                    >
                      <el-table-column prop="iccid" min-width="215">
                        <template slot-scope="scope">
                          <div slot="reference">
                            <span
                              title="ICCID"
                              :id="'iccid_' + scope.row.iccid"
                              >{{ scope.row.iccid }}</span
                            >
                            <i
                              class="el-icon-copy-document"
                              id="copyBtn"
                              title="复制"
                              data-clipboard-action="copy"
                              :data-clipboard-target="
                                '#iccid_' + scope.row.iccid
                              "
                              :data-clipboard-text="scope.row.iccid"
                              @click="onCopyIccid()"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="supplier" min-width="160">
                        <template slot-scope="scope">
                          <div slot="reference">
                            <span title="渠道">{{ scope.row.supplier }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="card_class" min-width="80">
                        <template slot-scope="scope">
                          <div slot="reference">
                            <span title="号卡类别">{{
                              scope.row.card_class
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="real_name_status" min-width="80">
                        <template slot-scope="scope">
                          <div slot="reference">
                            <span title="实名状态">{{
                              scope.row.real_name_status
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="arrearage_status" min-width="80">
                        <template slot-scope="scope">
                          <div slot="reference">
                            <span title="号卡状态">{{
                              scope.row.arrearage_status
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="activation_date" min-width="160">
                        <template slot-scope="scope">
                          <div slot="reference">
                            <span
                              v-if="scope.row.arrearage_status === '正常'"
                              title="使用时长"
                              >{{ DiffNowMin(scope.row.activation_date) }}</span
                            >
                            <span v-else>--</span>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div
                      class="moreBtn"
                      @click="showAll"
                      v-if="
                        JSON.parse(selectedUserObj.userInfo.iccid).length > 3
                      "
                    >
                      {{ iccidHeight === "150px" ? "展开 ∇" : "收起 ∆" }}
                    </div>
                  </div>
                </div>
              </el-header>
              <el-main
                :style="{
                  height: `calc(100vh - 300px - 20px - ${iccidHeight})`,
                }"
              >
                <el-tabs type="border-card">
                  <el-tab-pane label="工单记录">
                    <workTable :tableData="sheetList" />
                  </el-tab-pane>
                  <el-tab-pane label="添加工单">
                    <workAdd
                      :selectedUserObj="selectedUserObj"
                      :typeList="typeList"
                      @addSuccess="getUserSheetList"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="订单记录">
                    <orderTable :tableData="orderList" />
                  </el-tab-pane>
                  <el-tab-pane label="用户补卡">
                    <reissueCard
                      :selectedUserObj="selectedUserObj"
                      :typeList="typeList"
                      :reasonList="reasonList"
                      @reissueSuccessForList="getUseOrderList"
                      @reissueSuccessForMsg="sendWxMsg"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="退款记录">
                    <refundTable :tableData="refundList" />
                  </el-tab-pane>
                  <el-tab-pane label="历史记录">
                    <historyTable :tableData="historyList" />
                  </el-tab-pane>
                  <el-tab-pane label="投诉记录">
                    <compliantTable :tableData="compliantList" />
                  </el-tab-pane>
                </el-tabs>
              </el-main>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-container>
    <refundLayer ref="refund" id="refund" :refundType="refundType" />
    <phraseEditDrawer
      :phraseEditDrawer="phraseEditDrawer"
      :phraseInfo="phraseInfo"
      @phraseEditDrawerClose="phraseEditDrawerClose"
    />
    <myCenterDrawer
      :myCenterDrawer="myCenterDrawer"
      :serverId="`${serverId}`"
      :myCenterInfo="myCenterInfo"
      @clearLocalStorage="clearLocalStorage"
      @myCenterDrawerClose="myCenterDrawerClose"
      @websocketClose="websocketClose"
      @addPhrase="addPhrase"
    />
    <serveCloseDrawer
      :serveCloseDrawer="serveCloseDrawer"
      :summary="summary"
      :selectedUserObj="selectedUserObj"
      @serveCloseDrawerClose="serveCloseDrawerClose"
    />
  </div>
</template>

<script>
import indexjs from "./index.js";
import elImage from "@/plugins/elImage";
import moment from "moment";
import refundLayer from "@/components/refundLayer.vue";
import myCenterDrawer from "@/components/myCenterDrawer.vue";
import serveCloseDrawer from "@/components/serveCloseDrawer.vue";
import historyTable from "@/components/historyTable.vue";
import workTable from "@/components/workTable.vue";
import orderTable from "@/components/orderTable.vue";
import refundTable from "@/components/refundTable.vue";
import compliantTable from "@/components/compliantTable.vue";
import reissueCard from "@/components/reissueCard.vue";
import workAdd from "@/components/workAdd.vue";
import phraseEditDrawer from "@/components/phraseEditDrawer.vue";
import { copy } from "clipboard";
export default {
  methods: { copy },
  ...indexjs,
  components: {
    elImage,
    myCenterDrawer,
    refundLayer,
    serveCloseDrawer,
    historyTable,
    workTable,
    orderTable,
    refundTable,
    compliantTable,
    reissueCard,
    workAdd,
    phraseEditDrawer,
  },
  computed: {
    TimeLineDom() {
      return (timeStr) => {
        const tObj = new Date(timeStr);
        const nObj = Date.now();
        if (
          moment(tObj).format("YYYY-MM-DD") ===
          moment(nObj).format("YYYY-MM-DD")
        ) {
          return `今天 ${moment(tObj).format("HH:mm:ss")}`;
        }
        return moment(tObj).format("YYYY-MM-DD HH:mm:ss");
      };
    },
    doMsgDom() {
      return (msgContent) => {
        try {
          const strObj = JSON.parse(msgContent);
          return `🔗${strObj.content}`;
        } catch (e) {
          return `🔗${msgContent}`;
        }
      };
    },
    DiffNow() {
      // 撤回信息时长
      return (time) => -2 * 60 * 1000 < moment(time).diff(moment(Date.now()));
    },
    DiffNowMin() {
      // iccid使用时长
      return (time) => {
        const long = moment(Date.now()).diff(moment(time * 1000));
        return `${parseInt(long / 1000 / 60 / 60 / 24)}天${parseInt(
          ((long / 1000 / 60 / 60) % 24) / 60
        )}小时${parseInt(((long / 1000 / 60 / 60) % 24) % 60)}分`;
      };
    },
    DiffLastMsgTimeLine() {
      // 最后一条消息时间线
      return (list, index) => {
        if (list[index + 1] && list[index + 1].id) {
          return false;
          // (
          //   moment(list[index + 1].time_line).diff(
          //     moment(list[index].time_line),
          //     "hours"
          //   ) > 8
          // );
        } else {
          return (
            moment(Date.now()).diff(moment(list[index].time_line), "hours") > 1
          );
        }
        // 临近撤回消息的两条会不准确
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
