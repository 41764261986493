<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  beforeCreate() {
    const href = window.location.href;
    this.$store.commit("host/setEnv", {
      // env: href.indexOf("ser.wokahui.com") >= 0 ? "pro" : "dev",
      env: "dev",
    });
    console.log(
      `App.vue;${this.$store.state.host.env};v${this.$store.state.version};`
    );
  },
  methods: {},
};
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  /* 冲突，此句并不会产生效果 */
}

@keyframes blink {
  0% {
    filter: drop-shadow(0 0 2px #fff);
    transform: scale(1);
  }
  25% {
    filter: drop-shadow(0 0 2px #ff7a00);
    transform: scale(0.9);
  }
  50% {
    filter: drop-shadow(0 0 2px #fff);
    transform: scale(1);
  }
  75% {
    filter: drop-shadow(0 0 2px #ff7a00);
    transform: scale(0.9);
  }
}

.imgHint {
  animation-name: blink;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

.el-table .el-table__cell {
  text-align: center !important;
  padding: 10px 0 !important;
  cursor: default !important;
}

.w-e-full-screen-container {
  z-index: 999;
}
.w-e-text-container [data-slate-editor] {
  overflow: auto;
}
.w-e-text-container [data-slate-editor] p {
  margin: 0 !important;
}
.w-e-text-container [data-slate-editor] video {
  width: 100%;
}
.w-e-text-container [data-slate-editor] .w-e-textarea-video-container {
  background: #000;
  border: none;
}
.el-button--text {
  color: #777777 !important;
}
.el-popover {
  padding: 10px !important;
  .popover {
    max-height: calc(100vh - 350px - 60px - 44px);
    overflow: auto;
    .emojis {
      padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .emojy {
        margin: 2px;
        width: 24px;
        height: 24px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .phrases {
      padding: 10px;
      display: flex;
      flex-direction: column;

      .msg {
        margin-bottom: 5px;
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        > p:first-child {
          margin: 0;
          font-size: 14px;
          > i {
            margin: 0 2px;
            cursor: pointer;
            display: none;
          }
        }

        > p:first-child:hover > i {
          display: inline-block;
        }

        > p:last-child {
          margin: 5px;
          padding: 5px;
          font-size: 14px;
          background: #e7e9f2;
          border-radius: 5px;
        }
      }
      .add {
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
}

.el-select-dropdown {
  z-index: 29891014 !important;
}

.el-drawer__wrapper {
  left: calc((100% - 220px) / 2 + 220px - 10px) !important;
}
.el-popconfirm__main {
  padding: 8px 4px;
}

.el-image-viewer__wrapper {
  z-index: 20000 !important;
  pointer-events: none;
  .el-image-viewer__mask {
    pointer-events: inherit;
    display: none;
  }
  .el-image-viewer__canvas {
    pointer-events: inherit;
    > img {
      box-shadow: 0 0 20px 0 #606266;
      pointer-events: auto;
    }
  }
  .el-image-viewer__close {
    pointer-events: auto;
    top: auto;
    left: calc(50% + 156px);
    bottom: 30px;
    right: auto;
    width: 44px;
    height: 44px;
  }
  .el-image-viewer__actions {
    pointer-events: auto;
  }
}
</style>
