import Vue from "vue";
import Router from "vue-router";
import { themeRouteProps, ThemeRender } from "vue-multi-theme";
Vue.use(Router);

import Home from "@/views/home/index/index.vue";

// 路由
export const router = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/login/index.vue"),
  },
  {
    path: "/",
    name: "home",
    component: ThemeRender,
    props: themeRouteProps("Home", Home),
  },
];

// 防止连续点击多次路由报错
let originPush = Router.prototype.push; //备份原push方法
Router.prototype.push = function (location, resolve, reject) {
  // return routerPush.call(this, location).catch(err => err)
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

// 重写路由
export default new Router({
  mode: "hash", // history去掉url中的#,但重定向有问题
  scrollBehavior: () => ({ y: 0 }),
  routes: router,
});
