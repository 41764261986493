<template>
  <div class="content">
    <el-table
      :data="tableData"
      stripe
      fit
      style="min-width: 100%"
      height="100%"
    >
      <el-table-column
        prop="name"
        label="客户昵称"
        min-width="140"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="iccid"
        label="ICCID"
        min-width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        min-width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="state"
        label="类别"
        min-width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-html="scope.row.state" />
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        min-width="180"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-html="scope.row.content" />
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        min-width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-html="scope.row.status" />
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="提交时间"
        min-width="160"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "compliant", // 用户投诉记录
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.tableData = [];
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 100%;
  height: 100%;
  border: 1px solid #e7e9f2;
  ::v-deep .el-table__body-wrapper,
  ::v-deep .el-table__empty-block {
    width: 100% !important;
    min-height: 337px !important;
  }
}
</style>
