<template>
  <el-drawer
    title="个人中心"
    direction="rtl"
    :modal="false"
    :destroy-on-close="true"
    :visible="myCenterDrawer"
    @close="myCenterDrawerClose"
  >
    <div class="myCenter">
      <div class="action">
        <!--        <div class="item">-->
        <!--          <i-->
        <!--            class="el-icon-finished"-->
        <!--            title="历史服务用户"-->
        <!--            @click="developing()"-->
        <!--          ></i>-->
        <!--        </div>-->
        <div class="item" title="添加快捷回复" @click="addPhrase()">
          <i class="el-icon-document-add"></i>
        </div>
        <div class="item" title="修改密码" @click="openSetPwd()">
          <i class="el-icon-key"></i>
        </div>
        <!--        <div class="item" title="绑定钉钉" @click="bindDdLogin()">-->
        <!--          <i class="el-icon-full-screen"></i>-->
        <!--        </div>-->
        <div class="item" title="退出登录" @click="openLogout()">
          <i class="el-icon-switch-button"></i>
        </div>
      </div>
      <div class="info">
        <div class="title">
          <span>基本信息</span>
        </div>
        <div class="content">
          <p>
            昵称：<span>{{ $store.state.auth.loginInfo.user_name }}</span>
          </p>
          <p>
            工号：<span>{{ $store.state.auth.loginInfo.job_num }}</span>
          </p>
          <p>
            今日接入：<span>{{ myCenterInfo.num || 0 }}人</span>
          </p>
          <p>
            今日解决：<span>{{ myCenterInfo.solve || 0 }}人</span>
          </p>
          <p>
            服务解决率：<span>
              {{
                (myCenterInfo.solve || 0) / (myCenterInfo.solve || 0) || "-"
              }}%
            </span>
          </p>
          <p>
            我的排名：<span>{{ myCenterInfo.my_sort || "-" }}</span>
          </p>
        </div>
      </div>
      <div class="ranking">
        <div class="title">
          <span>昨日部门综合排行榜</span>
        </div>
        <div class="content">
          <div
            class="list1"
            v-if="myCenterInfo.yestady && myCenterInfo.yestady.length > 0"
          >
            <h1>服务人数排名</h1>
            <p v-for="(item1, index1) in myCenterInfo.yestady" :key="item1">
              <span>{{ index1 + 1 }}</span>
              {{ item1.name }}：{{ item1.num }}人
            </p>
          </div>
          <div v-else class="list1">
            <h1>服务人数排名</h1>
            <p style="text-align: center">暂无数据</p>
          </div>
          <div
            class="list2"
            v-if="myCenterInfo.efficiency && myCenterInfo.efficiency.length > 0"
          >
            <h1>一次解决率排名</h1>
            <p v-for="(item2, index2) in myCenterInfo.efficiency" :key="item2">
              <span>{{ index2 + 1 }}</span>
              {{ item2.name }}：{{ item2.rep }}%
            </p>
          </div>
          <div v-else class="list2">
            <h1>一次解决率排名</h1>
            <p style="text-align: center">暂无数据</p>
          </div>
        </div>
      </div>
      <div class="hideBox" @click.stop="clearLocalStorage()" />
    </div>

    <el-dialog title="修改密码" append-to-body :visible.sync="pwdDialogVisible">
      <el-form :model="pwdForm">
        <el-form-item label="新密码">
          <el-input v-model="pwdForm.pwd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input v-model="pwdForm.pwdTwo" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pwdDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setPwd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="退出登录"
      append-to-body
      :visible.sync="logoutDialogVisible"
    >
      <span>您即将退出登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doLogout()"> 确 定 </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="钉钉扫码绑定"
      append-to-body
      :visible.sync="ddDialogVisible"
    >
      <div
        style="display: flex; justify-content: center; align-items: center"
        id="ddQr"
      ></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ddDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </el-drawer>
</template>

<script>
export default {
  name: "myCenterDrawer", // 个人中心
  props: {
    myCenterDrawer: {
      type: Boolean,
      required: true,
    },
    serverId: {
      type: String,
      required: true,
    },
    myCenterInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ruleForm: {},
      rules: {},
      pwdDialogVisible: false,
      pwdForm: {
        pwd: "",
        pwdTwo: "",
      },
      logoutDialogVisible: false,
      ddDialogVisible: false,

      clearNum: 0,
    };
  },
  watch: {},
  methods: {
    developing() {
      this.$message({
        message: "编程小哥奋力开发中！",
        type: "success",
      });
    },
    bindDdLogin() {
      if (this.$store.state.auth.loginInfo.dd_uid) {
        this.$message({
          message: "已绑定钉钉登录！",
          type: "success",
        });
      } else {
        this.ddDialogVisible = true;
        const self = this;
        setTimeout(() => {
          const appid = "dingoaye5srggczaqovfeu";
          const redirect_uri = `https://chat.wokahui.com/admin/user/notify?kf_id=${self.serverId}`;
          let obj = DDLogin({
            id: "ddQr",
            // goto这里需要对url整体做一个uriencode编码
            goto: encodeURIComponent(
              "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" +
                appid +
                "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
                encodeURIComponent(redirect_uri)
            ),
            style: "border:none;background-color:transparent;zoom:0.8;",
            width: "365",
            height: "400",
          });
          let hanndleMessage = function (event) {
            let origin = event.origin;
            // 判断是否来自ddLogin扫码事件
            if (origin === "https://login.dingtalk.com") {
              let loginTmpCode = event.data;
              // 这里url不用进行urlencode编码
              let url =
                "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" +
                appid +
                "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
                redirect_uri +
                "&loginTmpCode=" +
                loginTmpCode;
              window.location.href = url;
            }
          };
          if (typeof window.addEventListener !== "undefined") {
            window.addEventListener("message", hanndleMessage, false);
          } else if (typeof window.attachEvent !== "undefined") {
            window.attachEvent("onmessage", hanndleMessage);
          }
        }, 100);
      }
    },
    openSetPwd() {
      this.pwdDialogVisible = true;
    },
    async setPwd() {
      if (!this.pwdForm.pwd || !this.pwdForm.pwdTwo) {
        this.$message({
          message: "请输入新密码和确认新密码！",
          type: "warning",
        });
        return;
      }
      if (!(this.pwdForm.pwd === this.pwdForm.pwdTwo)) {
        this.$message({
          message: "新密码和确认新密码不一致！",
          type: "warning",
        });
        return;
      }
      const pwdRes = await this.$zkHttp.service$login$password({
        pass: this.pwdForm.pwd,
        kf_id: this.$store.state.auth.loginInfo.uid,
      });
      this.pwdDialogVisible = false;
      this.pwdForm.pwd = "";
      this.pwdForm.pwdTwo = "";
      this.$message({
        message: "密码修改成功,下次登录生效！",
        type: "success",
      });
      this.myCenterDrawerClose();
    },
    openLogout() {
      this.logoutDialogVisible = true;
    },
    async doLogout() {
      this.$zkHttp.interact$index$set_action({
        kf_id: this.$store.state.auth.loginInfo.uid,
        action_type: 2, // 1:上线,2:离线,3:暂停
      });
      await this.$zkHttp.interact$login$login_out({
        kf_id: this.$store.state.auth.loginInfo.uid,
      });
      this.$message({
        message: "退出登录成功",
        type: "success", // message type: success/warning/info/error
      });
      this.myCenterDrawerClose();
      this.websocketClose();
      // this.beforeunloadHandler();
      localStorage.removeItem("indexData");
      this.$store.commit("auth/setLoginOut", {});
      this.$router.replace("/login");
    },
    clearLocalStorage() {
      if (this.clearNum < 5) {
        this.clearNum++;
      } else {
        this.clearNum = 0;
        this.$emit("clearLocalStorage");
      }
    },
    myCenterDrawerClose() {
      this.$emit("myCenterDrawerClose");
    },
    websocketClose() {
      this.$emit("websocketClose");
    },
    addPhrase() {
      this.$emit("addPhrase");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.myCenter {
  width: 100%;
  display: flex;
  flex-direction: column;
  .action {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: inset 0 0 15px #e7e9f2;
      margin: 0 10px 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      > i {
        font-weight: bold;
        font-size: 18px;
        color: #212121;
      }
    }
  }
  .info {
    margin-top: 10px;
    width: 100%;
    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > span {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .content {
      margin-top: 10px;
      padding: 5px 0;
      width: 100%;
      border: 1px solid #e7e9f2;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > p {
        padding: 5px 10px;
        min-width: max-content;
        width: calc(50% - 20px);
        font-size: 14px;
        color: #909399;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > span {
          color: #000;
        }
      }
      .logout {
        margin: 5px auto 10px auto;
        width: 52%;
        text-align: center;
        font-size: 15px;
        color: #fff;
        line-height: 30px;
        background: #000;
        border-radius: 15px;
      }
    }
  }
  .ranking {
    margin-top: 20px;
    width: 100%;
    .title {
      width: 100%;
      > span {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .content {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .list1,
      .list2 {
        width: calc(50% - 30px);
        padding: 10px;
        border: 1px solid #e7e9f2;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        > h1 {
          margin: 5px;
          font-size: 14px;
          text-align: center;
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > p {
          padding: 5px;
          font-size: 14px;
          color: #909399;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          > span {
            margin-right: 5px;
          }
        }
        > p:nth-child(2) > span,
        > p:nth-child(3) > span,
        > p:nth-child(4) > span {
          color: red;
        }
      }
    }
  }
  .hideBox {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: transparent;
  }
}
</style>
