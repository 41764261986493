<template>
  <el-drawer
    title="快速回复"
    direction="rtl"
    :modal="false"
    :destroy-on-close="true"
    :visible="phraseEditDrawer"
    @close="phraseEditDrawerClose"
  >
    <div class="phraseEdit">
      <el-form
        ref="phraseEditForm"
        :model="phraseEditForm"
        :rules="phraseEditFormRules"
        label-width="106px"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="phraseEditForm.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="消息类别" prop="msgtype">
          <el-select
            v-model="phraseEditForm.msgtype"
            placeholder="请选择消息类别"
          >
            <el-option
              v-for="item in [
                {
                  msgtype: 0,
                  name: '文本',
                },
              ]"
              :key="item.msgtype"
              :label="item.name"
              :value="item.msgtype"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回复内容" prop="content" style="width: 100%">
          <el-input
            type="textarea"
            :rows="3"
            v-model="phraseEditForm.content"
            placeholder="请输入回复内容"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('phraseEditForm')">重置</el-button>
          <el-button type="primary" @click="submitForm('phraseEditForm')">
            立即提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "phraseEditDrawer", // 添加或编辑快速回复
  props: {
    phraseEditDrawer: {
      type: Boolean,
      required: true,
    },
    phraseInfo: {
      type: Object,
    },
  },
  data() {
    return {
      phraseEditForm: {
        msgtype: 0,
        title: "",
        content: "",
        id: "",
      },
      phraseEditFormRules: {
        msgtype: [
          { required: false, message: "请选择消息类别", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [
          { required: true, message: "请输入回复内容", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    phraseInfo(newValue) {
      this.phraseInfo = newValue;
      this.phraseEditForm = {
        title: newValue.title,
        content: newValue.content,
        msgtype: newValue.msgtype,
        id: newValue.id,
      };
    },
  },
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const phRes = await this.$zkHttp.interact$service$add_quick_words({
            kf_id: this.$store.state.auth.loginInfo.uid,
            kf_name: this.$store.state.auth.loginInfo.user_name,
            title: this.phraseEditForm.title,
            content: this.phraseEditForm.content,
            msgtype: this.phraseEditForm.msgtype || 0,
            id: this.phraseEditForm.id,
          });
          this.$message({
            message: "提交成功！",
            type: "success",
          });
          this.resetForm(formName);
          this.phraseEditDrawerClose();
        } else {
          this.$message({
            message: "请完整且正确填写后提交！",
            type: "error",
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    phraseEditDrawerClose() {
      this.$emit("phraseEditDrawerClose");
    },
  },
};
</script>

<style scoped>
.phraseEdit {
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  ::v-deep .el-form-item {
    margin-bottom: 15px;
    width: 49%;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item__error {
    padding-top: 2px;
  }
  ::v-deep .el-input-number {
    width: 100%;
  }
  ::v-deep .el-input-number__decrease,
  ::v-deep .el-input-number__increase {
    background: #fff;
  }
  ::v-deep .el-radio-group {
    width: 100%;
    text-align: left;
  }
}
@media screen and (max-width: 1500px) {
  ::v-deep .el-form-item {
    width: 100% !important;
  }
}
</style>
