<template>
  <div class="content">
    <el-table
      :data="tableData"
      stripe
      fit
      style="min-width: 100%"
      height="100%"
      @row-dblclick="showWorkDetail"
    >
      <el-table-column
        prop="title"
        label="ICCID"
        min-width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        min-width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-html="scope.row.status" />
        </template>
      </el-table-column>
      <el-table-column
        prop="add_user"
        label="提交人"
        min-width="80"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="上传时间"
        min-width="140"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <workDetailLayer id="workDetail" :workDetail="workDetail" />
  </div>
</template>

<script>
import workDetailLayer from "@/components/workDetailLayer.vue";
export default {
  name: "workTable", // 工单记录
  components: {
    workDetailLayer,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      workDetail: {},
    };
  },
  mounted() {},
  methods: {
    async showWorkDetail(row) {
      const workId = (row && row.id) || "";
      if (workId) {
        const detailRes = await this.$zkHttp.interact$index$get_sheets_detail({
          id: workId,
        });
        this.workDetail = detailRes;
        layer.open({
          title: "工单详情",
          id: "workDetail",
          type: 1,
          offset: "rt",
          area: ["40vw", "100%"],
          shade: false, // 遮罩透明度
          shadeClose: false, // 点击遮罩区域，关闭弹层
          maxmin: true, // 允许全屏最小化
          resize: true, // 否允许拖拽弹层右下角拉伸尺寸
          scrollbar: false, // 打开弹层时，是否允许浏览器出现滚动条
          anim: 0, // 0-6 的动画形式，-1 不开启
          content: $("#workDetail"),
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 100%;
  height: 100%;
  border: 1px solid #e7e9f2;
  ::v-deep .el-table__body-wrapper,
  ::v-deep .el-table__empty-block {
    width: 100% !important;
    min-height: 337px !important;
  }
}
</style>
