<template>
  <div class="content">
    <div class="box">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="102px"
      >
        <el-form-item label="关联用户">
          <el-input v-model="selectedUserObj.userName" readonly></el-input>
        </el-form-item>
        <el-form-item label="关联ICCID" prop="iccid">
          <el-input
            v-model="ruleForm.iccid"
            placeholder="请输入关联ICCID"
          ></el-input>
        </el-form-item>
        <el-form-item label="工单类别" prop="type">
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择工单类别"
            @change="typeChange"
          >
            <el-option
              v-if="typeList.sheet_list"
              v-for="item in typeList.sheet_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优先级别" prop="priority">
          <el-radio-group v-model="ruleForm.priority">
            <el-radio label="一般" value="2"></el-radio>
            <el-radio label="加急" value="4"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入联系方式"
          ></el-input>
        </el-form-item>
        <div class="wangEditor">
          <div ref="workEditor" />
        </div>
        <el-form-item>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">
            立即提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Ed from "wangeditor";
export default {
  name: "workAddDrawer", // 提交工单
  // props: ["userId", "userName", "typeList"],
  props: {
    selectedUserObj: {
      type: Object,
      required: true,
    },
    typeList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editor: null,
      ruleForm: {
        iccid: "",
        type: "",
        phone: "",
        priority: "",
        desc: "",
      },
      rules: {
        iccid: [
          { required: true, message: "请输入关联ICCID", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择工单类别", trigger: "change" },
        ],
        phone: [
          { required: false, message: "请选择联系方式", trigger: "change" },
        ],
        priority: [
          { required: true, message: "请选择优先级别", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    selectedUserObj(newValue) {
      this.selectedUserObj = newValue;
    },
  },
  mounted() {
    this.editorCreated();
  },
  methods: {
    editorCreated() {
      this.editor = new Ed(this.$refs.workEditor);
      console.log("workEditor", this.editor);
      console.log(this.editor.$toolbarElem);

      this.editor.config.menus = [
        "head",
        "bold",
        "underline",
        "fontSize",
        "foreColor",
        "backColor",
        "justify",
        "link",
        "image",
        "|",
        "undo",
        "redo",
      ];
      this.editor.config.placeholder = "请输入工单内容...";

      this.editor.config.customUploadImg = this.uploadImg;
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 2M
      this.editor.config.uploadImgMaxLength = 1; // 最多可上传几个文件，默认为 100
      this.editor.config.uploadImgAccept = ["image/*"]; // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []

      this.editor.config.customUploadVideo = this.uploadVideo;
      this.editor.config.customUploadImg = this.uploadImg;
      this.editor.config.uploadVideoMaxSize = 10 * 1024 * 1024; // 10M
      this.editor.config.uploadVideoMaxLength = 1; // 最多可上传几个文件，默认为 100
      this.editor.config.uploadVideoAccept = ["video/*"]; // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []

      this.editor.config.onchange = (html) => {
        console.log("this.editor.config.onChange", html);
        this.ruleForm.desc = html;
      };

      this.editor.create();
    },
    typeChange(e) {
      if (this.typeList.sheet_list && this.typeList.sheet_list.length > 0) {
        for (let i = 0; i < this.typeList.sheet_list.length; i++) {
          const item = this.typeList.sheet_list[i];
          if (item.id === e) {
            this.editor.txt.html(item.content || "");
          }
        }
      }
    },
    async uploadImg(file) {
      let imgData = new FormData();
      imgData.append("file", file);
      imgData.append("file_type", "image");
      //调用上传图片接口，上传图片
      const upRes = await this.$http
        .post(
          "https://mini.wokahui.com/interact/upload/up_file?token=" +
            this.$store.state.auth.loginInfo.token,
          imgData
        )
        .then((res) => {
          return res.body.data;
        });
      console.log("img upRes", upRes.full_url);
      this.editor.cmd.do(
        "insertHTML",
        `<img src="${upRes.full_url}" alt="""/>`
      );
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const addRes = await this.$zkHttp.interact$service$addSheet({
            kf_id: this.$store.state.auth.loginInfo.uid,
            uid: this.selectedUserObj.userId,
            user_name: this.selectedUserObj.userName,
            title: this.ruleForm.iccid,
            type: this.ruleForm.type,
            phone: this.ruleForm.phone,
            level: this.ruleForm.priority,
            content: this.ruleForm.desc,
          });
          if (addRes.status && addRes.status === 1) {
            await this.$zkHttp.interact$service$addSheeted({
              kf_id: this.$store.state.auth.loginInfo.uid,
              uid: this.selectedUserObj.userId,
              user_name: this.selectedUserObj.userName,
              title: this.ruleForm.iccid,
              type: this.ruleForm.type,
              phone: this.ruleForm.phone,
              level: this.ruleForm.priority,
              content: this.ruleForm.desc,
            });
            this.$message({
              message: "工单提交成功！",
              type: "success",
            });
            this.resetForm(formName);
            this.$emit("addSuccess");
          } else {
            this.$message({
              message: "工单提交成功！",
              type: "success",
            });
            this.resetForm(formName);
            this.$emit("addSuccess");
          }
        } else {
          this.$message({
            message: "请完整且正确填写后提交！",
            type: "error",
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.editor.txt.clear();
    },
  },
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid #e7e9f2;
  .box {
    padding: 15px;
    ::v-deep .el-select {
      width: 100%;
    }
    ::v-deep .el-form-item {
      margin-bottom: 15px;
    }
    ::v-deep .el-form-item__error {
      padding-top: 2px;
    }
    ::v-deep .el-input-number {
      width: 100%;
    }
    ::v-deep .el-input-number__decrease,
    ::v-deep .el-input-number__increase {
      background: #fff;
    }
    ::v-deep .el-radio-group {
      width: 100%;
      text-align: left;
    }
    .wangEditor {
      width: 100%;
      border: 1px solid #dcdfe6;
      margin-bottom: 15px;
    }
  }
}
</style>
