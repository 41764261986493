<template>
  <div class="content">
    <el-table
      :data="tableData"
      stripe
      fit
      style="min-width: 100%"
      height="100%"
      @row-dblclick="showRefundDetail"
    >
      <el-table-column
        prop="order_num"
        label="订单号"
        min-width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="iccid"
        label="ICCID"
        min-width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="money"
        label="退款金额"
        min-width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        min-width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-html="scope.row.status" />
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="时间"
        min-width="160"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <refundDetailLayer id="refundDetail" :refundDetail="refundDetail" />
  </div>
</template>

<script>
import refundDetailLayer from "@/components/refundDetailLayer.vue";

export default {
  name: "refundTable",
  components: { refundDetailLayer },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      refundDetail: {},
    };
  },
  mounted() {},
  methods: {
    async showRefundDetail(row) {
      const refundId = (row && row.id) || "";
      if (refundId) {
        const detailRes = await this.$zkHttp.interact$index$get_drawback_info({
          id: refundId,
        });
        this.refundDetail = detailRes;
        layer.open({
          title: "退款详情",
          id: "refundDetail",
          type: 1,
          offset: "rt",
          area: ["40vw", "100%"],
          shade: false, // 遮罩透明度
          shadeClose: false, // 点击遮罩区域，关闭弹层
          maxmin: true, // 允许全屏最小化
          resize: true, // 否允许拖拽弹层右下角拉伸尺寸
          scrollbar: false, // 打开弹层时，是否允许浏览器出现滚动条
          anim: 0, // 0-6 的动画形式，-1 不开启
          content: $("#refundDetail"),
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 100%;
  height: 100%;
  border: 1px solid #e7e9f2;
  ::v-deep .el-table__body-wrapper,
  ::v-deep .el-table__empty-block {
    width: 100% !important;
    min-height: 337px !important;
  }
}
</style>
