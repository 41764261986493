<template>
  <el-drawer
    title="结束服务"
    direction="rtl"
    :modal="false"
    :destroy-on-close="true"
    :visible="serveCloseDrawer"
    @close="serveCloseDrawerClose"
  >
    <div class="serveClose">
      <el-form
        ref="serveCloseForm"
        :model="serveCloseForm"
        :rules="serveCloseFormRules"
        label-width="106px"
      >
        <el-form-item label="问题是否解决" prop="radio">
          <el-radio-group v-model="serveCloseForm.radio">
            <el-radio :label="1">已解决</el-radio>
            <el-radio :label="0">未解决</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="咨询类别" prop="type">
          <el-tabs type="card" v-if="summary.tree && summary.tree.length > 0">
            <el-tab-pane
              v-for="(pane, i) in summary.tree"
              :label="pane.title"
              :key="i + '_' + pane.id"
            >
              <el-checkbox-group
                v-model="serveCloseForm.type"
                v-if="pane.son && pane.son.length > 0"
              >
                <el-checkbox
                  v-for="(item, j) in pane.son"
                  :label="`${item.id}`"
                  :key="j + '_' + item.id"
                >
                  {{ item.title }}
                </el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="ICCID" prop="iccid">
          <el-input
            v-model="serveCloseForm.iccid"
            placeholder="请输入ICCID"
          ></el-input>
        </el-form-item>
        <el-form-item label="咨询备注" prop="desc" style="width: 100%">
          <el-input
            type="textarea"
            placeholder="请输入咨询备注"
            v-model="serveCloseForm.desc"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="serveCloseDrawerClose">取消</el-button>
          <el-button type="primary" @click="serveCloseDrawerConfirm()">
            提交并结束服务
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "serveCloseDrawer", // 结束服务
  props: {
    serveCloseDrawer: {
      type: Boolean,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
    selectedUserObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serveCloseForm: {
        radio: 1,
        type: [],
        iccid: "",
        desc: "",
      },
      serveCloseFormRules: {
        radio: [
          { required: true, message: "请选择问题是否解决", trigger: "change" },
        ],
        type: [
          { required: false, message: "请选择咨询类别", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    selectedUserObj(newValue) {
      this.selectedUserObj = newValue;
    },
    summary(newValue) {
      console.log("newValue", newValue);
      if (newValue.ser) {
        this.serveCloseForm.desc = newValue.ser.ask_desc || "";
        this.serveCloseForm.type = newValue.ser.ask_type || [];
      }
    },
  },
  mounted() {
    if (this.summary.ser) {
      this.serveCloseForm.desc = this.summary.ser.ask_desc || "";
      this.serveCloseForm.type = this.summary.ser.ask_type || [];
    }
  },
  methods: {
    async serveCloseDrawerConfirm() {
      console.log("serveCloseDrawerConfirm:", this.serveCloseForm);
      if (this.serveCloseForm.radio === -1) {
        this.$message({
          message: "请勾选问题是否解决！",
          type: "warning",
        });
        return;
      }
      let ask_id = "";
      if (this.serveCloseForm.type && this.serveCloseForm.type.length > 0) {
        const ask_idList = this.serveCloseForm.type.filter(
          (item, index) =>
            this.serveCloseForm.type.indexOf(item) === index &&
            item !== "" &&
            item !== "0" &&
            item !== 0
        );
        console.log("ask_id:", ask_idList.join(","));
        ask_id = ask_idList.join(",");
      }
      console.log(
        "this.selectedUserObj.matchInfo",
        this.selectedUserObj.matchInfo
      );
      if (this.selectedUserObj.matchInfo && this.selectedUserObj.matchInfo.id) {
        const qRes = await this.$zkHttp.interact$index$put_problem({
          s_id: this.selectedUserObj.matchInfo.id,
          kf_id: this.$store.state.auth.loginInfo.uid,
          uid: this.selectedUserObj.userId,
          ask_id,
          solve: this.serveCloseForm.radio,
          iccid: this.serveCloseForm.iccid,
          content: this.serveCloseForm.desc,
        });
        this.$emit("serveCloseDrawerClose", true);
        const endRes = await this.$zkHttp.interact$index$end_service({
          kf_id: this.$store.state.auth.loginInfo.uid,
          uid: this.selectedUserObj.userId,
        });
        this.serveCloseForm = {
          radio: 1,
          type: [],
          iccid: "",
          desc: "",
        };
      } else {
        this.$message({
          message: "数据丢失！",
          type: "warning",
        });
      }
    },
    serveCloseDrawerClose() {
      this.serveCloseForm = {
        radio: 1,
        type: [],
        iccid: "",
        desc: "",
      };
      this.$emit("serveCloseDrawerClose");
    },
  },
};
</script>

<style scoped>
.serveClose {
  width: 100%;
  display: flex;
  flex-direction: column;
  :deep(.el-tabs__header) {
    margin: 0;
  }
  :deep(.el-tabs__content) {
    padding: 10px;
    border: 1px solid #dcdfe6;
    border-top: none;
    .el-checkbox {
      margin: 5px 20px 5px 0;
      min-width: 122px;
    }
  }
}
</style>
