import { doRequest } from "./http";

let chatApiHttp = {
  // 客服离线/上线
  interact$index$set_action: ({ kf_id, action_type }) => {
    return doRequest("POST", {
      url: "interact/index/set_action",
      body: { kf_id, action_type },
    });
  },
  // 客服聊天注册
  chat$index$setUserOnline: ({ uid }) => {
    return doRequest("POST", {
      url: "chat/index/setUserOnline",
      body: { uid },
    });
  },
  // 聊天初始化绑定
  chat$chat$bind: (data) => {
    return doRequest("POST", {
      url: "chat/chat/bind",
      body: {
        ...data,
      },
    });
  },
  // 配对聊天
  interact$index$add_service: ({
    uid,
    name,
    avatar,
    group_id,
    kf_id,
    kf_name,
  }) => {
    return doRequest("POST", {
      url: "interact/index/add_service",
      body: { uid, name, avatar, group_id, kf_id, kf_name },
    });
  },
  // 发送消息
  interact$index$insert_log: ({
    from_id,
    from_name,
    from_avatar,
    to_id,
    content,
    msgtype,
    quote_id,
  }) => {
    return doRequest("POST", {
      url: "interact/index/insert_log",
      body: {
        from_id,
        from_name,
        from_avatar,
        to_id,
        content,
        msgtype,
        quote_id,
      },
    });
  },
  // 撤回消息
  chat$index$reset: ({ id }) => {
    return doRequest("GET", {
      url: "chat/index/reset",
      params: {
        id,
      },
    });
  },
  // 获取聊天记录
  interact$index$get_chat_log: ({ uid, page }) => {
    return doRequest("POST", {
      url: "interact/index/get_chat_log",
      body: { uid, page },
    });
  },
  // 获取当日会话结束的客户
  interact$index$get_over_member: ({ kf_id }) => {
    return doRequest("GET", {
      url: "interact/index/get_over_member",
      params: {
        kf_id,
      },
    });
  },
  // 内部转接
  interact$index$change_service: ({ uid, kf_id }) => {
    return doRequest("POST", {
      url: "interact/index/change_service",
      body: {
        uid,
        kf_id,
      },
    });
  },
  // 用户转组
  interact$index$change_group: ({ uid, group }) => {
    return doRequest("POST", {
      url: "interact/index/change_group",
      body: {
        uid,
        group,
      },
    });
  },
  // 不文明申诉
  interact$service$block: ({ uid, reason }) => {
    return doRequest("POST", {
      url: "interact/service/block",
      body: {
        uid,
        reason,
      },
    });
  },
  // 记录客服首次响应用户
  interact$service$accept_first: ({ uid, s_id, kf_id }) => {
    return doRequest(
      "POST",
      {
        url: "interact/service/accept_first",
        body: {
          uid,
          s_id,
          kf_id,
        },
      },
      false
    );
  },
  // 结束会话，提交用户问题
  interact$index$put_problem: ({
    uid,
    s_id,
    kf_id,
    ask_id,
    content,
    solve,
    iccid,
  }) => {
    return doRequest("POST", {
      url: "interact/index/put_problem",
      body: {
        uid,
        s_id,
        kf_id,
        ask_id,
        content,
        solve,
        iccid,
      },
    });
  },
  // 结束关闭用户
  interact$index$end_service: ({ kf_id, uid }) => {
    return doRequest("POST", {
      url: "interact/index/end_service",
      body: {
        kf_id,
        uid,
      },
    });
  },
  chat$index$wait_warn: (data) => {
    return doRequest(
      "POST",
      {
        url: "chat/index/wait_warn",
        body: {
          ...data,
        },
      },
      false
    );
  },
  // 监听用户持卡的详情
  interact$index$listen: ({ uid, kf_id, iccid, supplier }) => {
    return doRequest("POST", {
      url: "interact/index/listen",
      body: {
        uid,
        kf_id,
        iccid,
        supplier,
      },
    });
  },
};

export default chatApiHttp;
