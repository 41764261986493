<template>
  <div class="refundDetail">
    <div class="refundInfo" v-if="refundDetail.info">
      <p style="width: 100%">
        <span>订单号:</span><span>{{ refundDetail.info.order_num }}</span>
      </p>
      <p>
        <span>订单金额(元):</span><span>{{ refundDetail.info.amount }}</span>
      </p>
      <p>
        <span>退款金额(元):</span><span>{{ refundDetail.info.money }}</span>
      </p>
      <p>
        <span>状态:</span>
        <span v-html="refundDetail.info.status"></span>
      </p>
      <p>
        <span>提交人:</span><span>{{ refundDetail.info.add_user }}</span>
      </p>
      <p>
        <span>联系电话:</span><span>{{ refundDetail.info.tel }}</span>
      </p>
      <p>
        <span>备注:</span><span>{{ refundDetail.info.back_desc }}</span>
      </p>
      <p style="width: 100%"><span>扣费明细:</span><span></span></p>
      <el-timeline
        v-if="
          refundDetail.info.amount_list &&
          refundDetail.info.amount_list.length > 0
        "
      >
        <el-timeline-item
          v-for="(item, index) in refundDetail.info.amount_list"
          :key="index"
          :timestamp="index"
          hide-timestamp
        >
          [{{ item.title || "**" }}]: ￥{{ item.amount || "0.0" }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <div
      class="refundLog"
      v-if="refundDetail.log && refundDetail.log.length > 0"
    >
      <p style="width: 100%"><span>操作记录:</span><span></span></p>
      <el-timeline v-if="refundDetail.log">
        <el-timeline-item
          v-for="(item, index) in refundDetail.log"
          :key="index"
          :timestamp="item.create_time"
        >
          [{{ item.name }}]: {{ item.remarks }}
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "refundDetailLayer", // 工单详情
  props: {
    refundDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.tableData = [];
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.refundDetail {
  padding: 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;

  .refundInfo,
  .refundLog {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > p {
      margin: 5px 0;
      width: 50%;
      display: flex;
      flex-direction: row;

      > span:first-child {
        width: 80px;
        text-align: right;
        display: inline-block;
        font-size: 13px;
        line-height: 25px;
        opacity: 0.5;
        margin-right: 5px;
      }

      > span:last-child {
        width: calc(100% - 80px);
        display: inline-block;
        font-size: 15px;
        line-height: 25px;
        word-break: break-all;
      }
    }

    .el-timeline {
      padding-inline-start: 70px;
      margin-block-start: 10px;
      margin-block-end: 10px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .refundDetail > p {
    width: 100% !important;
  }
}
</style>
<script setup></script>
