import moment from "moment/moment";

const state = {
  loginInfo: {
    token: "",
    uid: 0,
    user_name: "",
    avatar: "",
    role: 0,
    group: 0,
    max_num: 0,
    level: 0,
  },
};

const getters = {
  getLoginInfo(state) {
    return state.loginInfo;
  },
};

const mutations = {
  setLoginInfo(state, payload) {
    state.loginInfo = {
      token: payload.token,
      uid: payload.uid || 0,
      user_name: payload.user_name || "",
      avatar: payload.avatar || "",
      role: payload.role || 0,
      job_num: payload.job_num || 0,
      group: payload.group || 0,
      max_num: payload.max_num || 0,
      level: payload.level || 0,
      dd_uid: payload.dd_uid || "",
    };
    localStorage.setItem(
      "loginInfo",
      JSON.stringify({
        version: moment(Date.now()).format("YYYY-MM-DD"),
        data: {
          ...state.loginInfo,
        },
      })
    );
  },
  setLoginOut(state, payload) {
    state.loginInfo = {
      token: "",
      uid: payload.uid || 0,
      user_name: payload.user_name || "",
      avatar: payload.avatar || "",
      role: payload.role || 0,
      group: payload.group || 0,
      max_num: payload.max_num || 0,
      level: payload.level || 0,
    };
    localStorage.setItem(
      "loginInfo",
      JSON.stringify({
        version: moment(Date.now()).format("YYYY-MM-DD"),
        data: {
          ...state.loginInfo,
        },
      })
    );
  },
};

export default {
  namespaced: true, // 开启namespace:true，该模块就成为命名空间模块了
  state,
  getters,
  mutations,
};
