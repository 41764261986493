<template>
  <div class="content">
    <el-table
      :data="tableData"
      stripe
      fit
      style="min-width: 100%"
      height="100%"
      @row-dblclick="showOrderDetail"
    >
      <el-table-column
        prop="phone_num"
        label="原号卡"
        min-width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-html="scope.row.status" />
        </template>
      </el-table-column>
      <el-table-column
        prop="card_type"
        label="补卡类别"
        min-width="80"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="content"
        label="原因"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="申请时间"
        min-width="160"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="realname"
        label="姓名"
        min-width="80"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="tel"
        label="联系电话"
        min-width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="address"
        label="联系地址"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="add_user"
        label="提交客服"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <orderDetailLayer id="orderDetail" :orderDetail="orderDetail" />
  </div>
</template>

<script>
import orderDetailLayer from "@/components/orderDetailLayer.vue";
export default {
  name: "order",
  components: {
    orderDetailLayer,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      orderDetail: {},
    };
  },
  mounted() {},
  methods: {
    async showOrderDetail(row) {
      const orderId = (row && row.id) || "";
      if (orderId) {
        const detailRes = await this.$zkHttp.interact$index$get_saleds_detail({
          id: orderId,
        });
        this.orderDetail = detailRes;
        layer.open({
          title: "订单详情",
          id: "orderDetail",
          type: 1,
          offset: "rt",
          area: ["40vw", "100%"],
          shade: false, // 遮罩透明度
          shadeClose: false, // 点击遮罩区域，关闭弹层
          maxmin: true, // 允许全屏最小化
          resize: true, // 否允许拖拽弹层右下角拉伸尺寸
          scrollbar: false, // 打开弹层时，是否允许浏览器出现滚动条
          anim: 0, // 0-6 的动画形式，-1 不开启
          content: $("#orderDetail"),
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 100%;
  height: 100%;
  border: 1px solid #e7e9f2;
  ::v-deep .el-table__body-wrapper,
  ::v-deep .el-table__empty-block {
    width: 100% !important;
    min-height: 337px !important;
  }
}
</style>
