<template>
  <div class="orderDetail">
    <div class="orderInfo" v-if="orderDetail.info">
      <p>
        <span>姓名:</span><span>{{ orderDetail.info.realname }}</span>
      </p>
      <p>
        <span>联系电话:</span><span>{{ orderDetail.info.tel }}</span>
      </p>
      <p style="width: 100%">
        <span>收货地址:</span><span>{{ orderDetail.info.address }}</span>
      </p>
      <p>
        <span>原号卡:</span><span>{{ orderDetail.info.phone_num }}</span>
      </p>
      <p>
        <span>新号卡:</span><span>{{ orderDetail.info.iccid }}</span>
      </p>
      <p>
        <span>订单编号:</span><span>{{ orderDetail.info.order_num }}</span>
      </p>
      <p>
        <span>金额(元):</span><span>{{ orderDetail.info.money }}</span>
      </p>
      <p>
        <span>申领数量:</span><span>{{ orderDetail.info.num }}</span>
      </p>
      <p>
        <span>申领原因:</span><span>{{ orderDetail.info.content }}</span>
      </p>
      <p>
        <span>快递单号:</span>
        <span>{{ orderDetail.info.fast_num || orderDetail.log.no }}</span>
      </p>
      <p>
        <span>快递公司:</span>
        <span>{{ orderDetail.info.fast_name || orderDetail.log.com }}</span>
      </p>
      <p style="width: 100%">
        <span>备注:</span><span>{{ orderDetail.info.des }}</span>
      </p>
    </div>
    <div class="orderWuliu" v-if="orderDetail.log">
      <p style="width: 100%" v-if="orderDetail.log.list">
        <span>物流详情:</span><span></span>
      </p>
      <el-timeline v-if="orderDetail.log.list">
        <el-timeline-item
          v-for="(activity, index) in orderDetail.log.list"
          :key="index"
          :timestamp="activity.time"
        >
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderDetailLayer", // 工单详情
  props: {
    orderDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.tableData = [];
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.orderDetail {
  padding: 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;

  .orderInfo,
  .orderWuliu {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > p {
      margin: 5px 0;
      width: 50%;
      display: flex;
      flex-direction: row;

      > span:first-child {
        width: 65px;
        text-align: right;
        display: inline-block;
        font-size: 13px;
        line-height: 25px;
        opacity: 0.5;
        margin-right: 5px;
      }

      > span:last-child {
        width: calc(100% - 70px);
        display: inline-block;
        font-size: 15px;
        line-height: 25px;
        word-break: break-all;
      }
    }

    .el-timeline {
      padding-inline-start: 70px;
      margin-block-start: 10px;
      margin-block-end: 10px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .orderDetail > p {
    width: 100% !important;
  }
}
</style>
<script setup></script>
