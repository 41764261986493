const state = {
  env: "dev",
  apiHost: {
    dev: "https://mini.wokahui.com",
    uat: "https://mini.wokahui.com",
    pro: "https://chat.wokahui.com",
  },
  wsHost: {
    dev: "wss://mini.wokahui.com:8899",
    uat: "wss://mini.wokahui.com:8899",
    pro: "wss://chat.wokahui.com:8899",
  },
};
const mutations = {
  setEnv(state, payload) {
    state.env = payload.env;
  },
};
export default {
  namespaced: true, // 开启namespace:true，该模块就成为命名空间模块了
  state,
  mutations,
};
