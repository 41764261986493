import Vue from "vue";
import App from "./App.vue";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element);

import VueResource from "vue-resource";
Vue.use(VueResource);

import VueMultiTheme from "vue-multi-theme";
Vue.use(VueMultiTheme, {
  themeConfigs: require.context("./themes", true, /\/.*\/index\.js$/),
  themeName: localStorage.getItem("selectedTheme") || "default",
  onThemeChanged: (themeOptions) => {},
});

Vue.prototype.$message = (option) => {
  option.duration =
    option.duration || option.duration === 0 ? option.duration : 3000;
  option.showClose = true;
  option.offset = 6;
  return Element.Message(option);
};

import zkHttp from "@/config/api/index.js";
Vue.prototype.$zkHttp = zkHttp;

import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
