import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.js";
import host from "./modules/host.js";
import config from "../../package.json";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: config.version,
    loading: 0,
    themes: [
      { key: "default", label: "雅致黑" },
      { key: "theme01", label: "优雅蓝" },
      { key: "theme02", label: "清新蓝" },
      { key: "theme03", label: "高贵紫" },
      { key: "theme04", label: "玫瑰红" },
      { key: "theme05", label: "浪漫粉" },
      { key: "theme06", label: "活力橙" },
      { key: "theme07", label: "清爽绿" },
      { key: "theme08", label: "碧湖绿" },
      { key: "theme09", label: "格调咖" },
    ],
    selectedTheme: "default",
  },
  mutations: {
    showLoading(state) {
      state.loading++;
    },
    hideLoading(state) {
      if (state.loading > 0) state.loading--;
    },
    setSelectedTheme(state, payload) {
      state.selectedTheme = payload;
      localStorage.setItem("selectedTheme", state.selectedTheme);
    },
  },
  modules: {
    host,
    auth,
  },
});
