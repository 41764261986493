<template>
  <div class="content">
    <el-table
      :data="tableData"
      stripe
      fit
      style="min-width: 100%"
      height="100%"
    >
      <el-table-column
        prop="name"
        label="用户昵称"
        min-width="140"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="kf_name"
        label="客服昵称"
        min-width="140"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="ask_type"
        label="问题类别"
        min-width="80"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="solve"
        label="是否解决"
        min-width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ ["未解决", "已解决"][scope.row.solve] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="start_time"
        label="开始时间"
        min-width="140"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="end_time"
        label="结束时间"
        min-width="140"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "history",
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.tableData = this.tableData || [];
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 100%;
  height: 100%;
  border: 1px solid #e7e9f2;
  ::v-deep .el-table__body-wrapper,
  ::v-deep .el-table__empty-block {
    width: 100% !important;
    min-height: 337px !important;
  }
}
</style>
