<template>
  <div class="content">
    <div class="box">
      <el-form
        ref="ruleForm"
        class="demo-ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="102px"
      >
        <el-form-item label="关联用户">
          <el-input v-model="selectedUserObj.userName" readonly></el-input>
        </el-form-item>
        <el-form-item label="原号卡" prop="old">
          <el-input
            v-model="ruleForm.old"
            placeholder="请输入原号卡"
          ></el-input>
        </el-form-item>
        <el-form-item label="补卡类别" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择补卡类别">
            <el-option
              v-if="typeList.cards_list"
              v-for="item in typeList.cards_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货仓库" prop="entrepot">
          <el-select v-model="ruleForm.entrepot" placeholder="请选择发货仓库">
            <el-option label="恩施" value="0" key="0"></el-option>
            <el-option label="深圳" value="1" key="1"></el-option>
            <el-option label="武汉" value="2" key="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补卡数量" prop="number">
          <el-input-number
            v-model="ruleForm.number"
            :min="1"
            :max="10"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="补卡原因" prop="reason">
          <el-select
            v-model="ruleForm.reason"
            placeholder="请选择补卡原因"
            @change="reasonChange"
          >
            <el-option
              v-if="reasonList"
              v-for="item in reasonList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补卡金额(元)" prop="price">
          <el-input
            v-model="ruleForm.price"
            placeholder="请输入补卡金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">
            立即提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "replace", // 用户补卡
  props: {
    selectedUserObj: {
      type: Object,
      required: true,
    },
    typeList: {
      type: Object,
      required: true,
    },
    reasonList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ruleForm: {
        old: "",
        type: "",
        entrepot: "",
        number: "",
        reason: "",
        price: "",
        times: "0",
        remark: "",
      },
      rules: {
        old: [{ required: true, message: "请输入原号卡", trigger: "blur" }],
        type: [
          { required: true, message: "请选择补卡类别", trigger: "change" },
        ],
        entrepot: [
          { required: true, message: "请选择发货仓库", trigger: "change" },
        ],
        number: [
          { required: true, message: "请输入补卡数量", trigger: "blur" },
        ],
        reason: [
          { required: true, message: "请选择发货仓库", trigger: "change" },
        ],
        price: [{ required: true, message: "请输入补卡金额", trigger: "blur" }],
      },
    };
  },
  watch: {
    selectedUserObj(newValue) {
      this.selectedUserObj = newValue;
    },
  },
  methods: {
    reasonChange(e) {
      console.log("reasonChange", e);
      this.ruleForm.price = this.reasonList[e - 1].money || 0;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const addRes = await this.$zkHttp.interact$service$addCard({
            kf_id: this.$store.state.auth.loginInfo.uid,
            uid: this.selectedUserObj.userId,
            user_name: this.selectedUserObj.userName,
            store: this.ruleForm.entrepot,
            phone_num: this.ruleForm.old,
            card_type: this.ruleForm.type,
            content: this.ruleForm.reason,
            num: this.ruleForm.number,
            money: this.ruleForm.price,
            des: this.ruleForm.remark,
          });
          if (addRes.status && addRes.status === 1) {
            this.$message({
              message: "补卡记录已存在！",
              type: "waring",
            });
          } else {
            this.$message({
              message: "补卡提交成功！",
              type: "success",
            });
            this.resetForm(formName);
            this.$emit("reissueSuccessForMsg", 3, addRes.id);
            this.$emit("reissueSuccessForList");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid #e7e9f2;
  .box {
    padding: 15px;
    ::v-deep .el-select {
      width: 100%;
    }
    ::v-deep .el-form-item {
      margin-bottom: 15px;
    }
    ::v-deep .el-form-item__error {
      padding-top: 2px;
    }
    ::v-deep .el-input-number {
      width: 100%;
    }
    ::v-deep .el-input-number__decrease,
    ::v-deep .el-input-number__increase {
      background: #fff;
    }
  }
}
</style>
